import { makeObservable, observable, action } from 'mobx';
import { Subject } from 'rxjs';
import { NodeBaseDto } from '../../types';
import { InputBaseModel, SourceModelBase } from '..';

export default abstract class NodeBaseModel {
    readonly id: string;

    readonly type: string;

    readonly name: string;

    readonly inputValueChangeSubject = new Subject<InputBaseModel>();

    readonly inputValidationSubject = new Subject<InputBaseModel>();

    childNodes: NodeBaseModel[] = [];

    hasError: boolean = false;

    constructor(data: NodeBaseDto) {
        this.id = data.id;
        this.type = data.type;
        this.name = data.name;

        makeObservable(this, {
            childNodes: observable,
            hasError: observable,
            setHasError: action,
            setChildNodes: action
        });
    }

    setChildNodes(childNodes: NodeBaseModel[]) {
        this.childNodes = childNodes;
    }

    setHasError(hasError: boolean) {
        this.hasError = hasError;
    }

    getInputByGuid(guid: string) {
        return this.inputs.find(i => i.guid === guid);
    }

    abstract update(data: NodeBaseDto, sources: SourceModelBase[]): void;

    abstract get inputs(): InputBaseModel[];

    abstract get dto(): NodeBaseDto;

    abstract getInputsAtSameLevel(inputGuid: string): InputBaseModel[];
}
