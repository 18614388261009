import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu } from 'antd';
import { SettingsIcon } from '../../../custom_shared/icons';
import { useAppStore } from '../../stores';

const key = 'adjust-complexity';

const AdjustComplexityMenuItem: React.FC = () => {
    const { settingsStore } = useAppStore();

    return (
        <Menu.Item key={key} eventKey={key} icon={<SettingsIcon />} onClick={() => settingsStore.setModalVisible(true)}>
            Adjust Complexity
        </Menu.Item>
    );
};

export default observer(AdjustComplexityMenuItem);
