import { DynamicUiModel } from '../../common/stores/DynamicUiModel';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SectionType, FormInputFieldData, FormInputParams } from '../../common/services/types';
import { DynamicContainerInput, SessionInfoLabel } from '.';
import { Button, Col, Dropdown, Menu, Row, Tabs, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import Form from 'antd/lib/form/Form';
import Registry from './DynamicControlsRegistry';
import FormItem from 'antd/lib/form/FormItem';
import { Subject } from 'rxjs';

const { TabPane } = Tabs;

type Props = {
    ui: DynamicUiModel;
    onHighlightBlock: (field: FormInputFieldData, inputId: string) => void;
    form: FormInstance;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleAction: (control: string, actionId: string, value: any) => void;
    getGearIcon: (inputParams: FormInputParams) => false | JSX.Element;
    handleFormFinish: () => Promise<void>;
    onDownload?: (filePath: string) => void;
    highlightInputSubject?: Subject<string>;
    handleActionClick: (actionId: string) => void;
    showingBlocks: boolean;
    onReload?: () => void;
    onTogglePageBlocks: () => void;
    dataId?: string;
    appName?: string;
    sessionState?: string;
    restricedModeEnabled?: boolean;
};

const DynamicControlsBottomContainer: React.FC<Props> = ({
    ui,
    onHighlightBlock,
    form,
    handleAction,
    handleActionClick,
    getGearIcon,
    onDownload,
    highlightInputSubject,
    onReload,
    onTogglePageBlocks,
    showingBlocks,
    dataId,
    appName,
    sessionState,
    handleFormFinish,
    restricedModeEnabled
}) => {
    const renderContainerInputs = (section: string | undefined) => {
        if (ui.containerInputs) {
            var containerInputs = ui.containerInputs.filter(i => i.section === section);
            if (containerInputs && containerInputs.length > 0) {
                return (
                    <div>
                        {containerInputs.map(c => (
                            <DynamicContainerInput
                                key={c.id}
                                containerInput={c}
                                ui={ui}
                                form={form}
                                onHighlightBlock={onHighlightBlock}
                                getGearIcon={() => {
                                    return <></>;
                                }}
                            />
                        ))}
                    </div>
                );
            }
        }

        return null;
    };

    const renderInputs = (section: string | undefined | null) => {
        return (
            // tslint:disable-next-line:triple-equals
            ui.inputs
                .filter(i => i.section === section)
                .map((inputParams, index) => {
                    let WidgetComponent = Registry[inputParams.controlType]!;
                    return (
                        <WidgetComponent
                            key={index}
                            inputParams={inputParams}
                            onAction={handleAction}
                            ui={ui}
                            form={form}
                            onHighlightBlock={onHighlightBlock}
                            highlightInputSubject={highlightInputSubject}
                            getGearIcon={getGearIcon}
                            onDownload={onDownload}
                        />
                    );
                })
        );
    };

    const moreActionsMenu = (
        <Menu>
            {ui.actions &&
                ui.actions.length > 1 &&
                sessionState !== 'Archived' &&
                ui.actions.map((actions, index) => {
                    if (index === 0) {
                        return null;
                    }

                    return (
                        <Menu.Item key={index} onClick={() => handleActionClick(actions.id)}>
                            {actions.name}
                        </Menu.Item>
                    );
                })}
        </Menu>
    );

    const renderErrors = () => {
        if (!ui || !ui.errors || !ui.errors.length) {
            return null;
        }
        const errorMsg = ui.errors.map((err, i) => (
            <span key={`${err.errorCode}-${i}`}>
                {err.message}: {err.details}
            </span>
        ));
        return (
            <Tooltip overlayStyle={{ maxWidth: 600 }} placement="topLeft" title={errorMsg}>
                <div
                    style={{
                        maxHeight: 20,
                        flex: '1 1',
                        padding: '0 18px 18px',
                        overflow: 'hidden',
                        maxWidth: 'calc(100wv - 24px)',
                        wordBreak: 'break-word',
                        color: 'red'
                    }}
                >
                    {errorMsg}
                </div>
            </Tooltip>
        );
    };

    const renderActions = () => {
        if (!ui || !ui.layoutConfiguration || ui.layoutConfiguration.sizeRatio > 0.1) {
            return null;
        }

        return (
            <FormItem className="alpha-application-form-buttons inline">
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <SessionInfoLabel infoText={ui.additionalInfo} placement="left" />
                        {ui.actions && ui.actions.length > 1 && sessionState !== 'Archived' && (
                            <Dropdown overlay={moreActionsMenu} trigger={['click']}>
                                <Button>More actions...</Button>
                            </Dropdown>
                        )}
                        {ui.actions && ui.actions.length ? (
                            <>
                                {!restricedModeEnabled && onReload ? (
                                    <Tooltip title="Reload">
                                        <Button
                                            type="link"
                                            className="reload-button"
                                            onClick={onReload}
                                            data-id={`alpha-application-form-reload-bottom-${dataId ? dataId : 'undefined'}`}
                                        >
                                            <i className="alpha-icon reload xs" />
                                        </Button>
                                    </Tooltip>
                                ) : null}

                                <Tooltip title="Toggle found blocks">
                                    <Button
                                        type="link"
                                        className="reload-button"
                                        onClick={onTogglePageBlocks}
                                        data-id={`alpha-application-form-blocks-bottom-${dataId ? dataId : 'undefined'}`}
                                    >
                                        <i
                                            className={`alpha-icon xs ${showingBlocks ? 'eye-visible' : 'eye-invisible'}`}
                                            style={{ width: '21px' }}
                                        />
                                    </Button>
                                </Tooltip>
                            </>
                        ) : null}
                        {ui.actions
                            .filter(a => a.isPrimary)
                            .map(a => (
                                <Button
                                    key={a.id}
                                    className="submit-button"
                                    name={`${a.name}-button`}
                                    htmlType="submit"
                                    type="primary"
                                    data-id={`alpha-application-form-submit-bottom-${dataId ? dataId : 'undefined'}`}
                                    onClick={() => handleActionClick(a.id)}
                                    disabled={a.isDisabled}
                                >
                                    {a.name}
                                </Button>
                            ))}
                        {ui.actions[0] && sessionState !== 'Archived' && (
                            <Button
                                key={0}
                                className="submit-button"
                                name={'submitButton'}
                                htmlType="submit"
                                type="primary"
                                data-id={`alpha-application-form-submit-bottom-${dataId ? dataId : 'undefined'}`}
                                onClick={() => handleActionClick(ui.actions[0].id)}
                                disabled={ui.actions[0].isDisabled}
                            >
                                {ui.actions[0].name}
                            </Button>
                        )}
                    </Col>
                </Row>
            </FormItem>
        );
    };

    const renderHeader = () => {
        if (!ui || !ui.layoutConfiguration || ui.layoutConfiguration.sizeRatio > 0.1 || !appName) {
            return null;
        }
        return (
            <div className="bottom-container-header">
                <span className="app-title">{appName}</span>
                {renderActions()}
            </div>
        );
    };

    var tabs = ui.sections.filter(s => s && s.type === SectionType.Tab);

    return (
        <div
            className={`bottom-container-content ${ui && ui.errors && ui.errors.length ? 'has-errors' : ''} ${tabs.length < 2 ? 'tabs-hidden' : ''}`}
        >
            {renderHeader()}
            <Tabs
                defaultActiveKey="0"
                tabPosition={'bottom'}
                tabBarStyle={{ display: tabs.length > 1 ? 'block' : 'none' }}
            >
                {tabs.map((x, i) => (
                    <TabPane tab={x!.name} key={i} forceRender>
                        <Form
                            form={form}
                            onFinish={handleFormFinish}
                            data-id={`alpha-application-form-bottom-${i}-${dataId ? dataId : 'undefined'}`}
                        >
                            {renderInputs(x!.id)}
                            {renderContainerInputs(x!.id)}
                        </Form>
                    </TabPane>
                ))}
            </Tabs>
            {renderErrors()}
        </div>
    );
};

export default observer(DynamicControlsBottomContainer);
