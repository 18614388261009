import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { NodeProps } from '../../componentTypes';
import {
    AdjustmentsCountInput,
    FirstPeriodCountInput,
    FirstPeriodTimeInput,
    SubsequentAdjustmentsPeriodCountInput,
    SubsequentAdjustmentsPeriodTimeInput,
    SubmissionWithinCountInput,
    SubmissionWithinPeriodInput,
    PaymentWithinCountInput,
    PaymentWithinPeriodInput
} from '../../node_inputs/AdjustmentsNodeInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils, InputIds } from '../../misc';
import { useNodeForm } from '../../hooks';

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.Adjustments.AdjustmentsCount]: AdjustmentsCountInput,
    [InputIds.Adjustments.FirstPeriodCount]: FirstPeriodCountInput,
    [InputIds.Adjustments.FirstPeriodTime]: FirstPeriodTimeInput,
    [InputIds.Adjustments.SubsequentAdjustmentsPeriodCount]: SubsequentAdjustmentsPeriodCountInput,
    [InputIds.Adjustments.SubsequentAdjustmentsPeriodTime]: SubsequentAdjustmentsPeriodTimeInput,
    [InputIds.Adjustments.SubmissionWithinCount]: SubmissionWithinCountInput,
    [InputIds.Adjustments.SubmissionWithinPeriod]: SubmissionWithinPeriodInput,
    [InputIds.Adjustments.PaymentWithinCount]: PaymentWithinCountInput,
    [InputIds.Adjustments.PaymentWithinPeriod]: PaymentWithinPeriodInput
} as const;

const AdjustmentsNodeRenderer: React.FC<NodeProps> = ({ node }: NodeProps) => {
    const { form, formKey, onValuesChange } = useNodeForm(node);

    const getComponentRows = React.useCallback((inputs: InputBaseModel[]) => {
        const rows: JSX.Element[][] = [];

        const adjustmentsCountInput = inputs.find(i => i.id === InputIds.Adjustments.AdjustmentsCount);

        if (adjustmentsCountInput) {
            rows.push([
                <InputRenderer
                    key={adjustmentsCountInput.id}
                    input={adjustmentsCountInput}
                    component={InputComponents[adjustmentsCountInput.id]}
                />
            ]);
        }

        const restInputs = inputs.filter(i => i.id !== InputIds.Adjustments.AdjustmentsCount);

        rows.push(
            ...Utils.groupArray(restInputs, 2).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            )
        );

        return rows;
    }, []);

    return (
        <LayoutRenderer
            node={node}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(AdjustmentsNodeRenderer);
