import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { message } from 'antd';
import { BusinessRequest, BusinessDto, BusinessPartnerDto } from '../types';
import { ContractsIngestionService } from '../services';
import { InputBaseModel } from '../models';
import AppStore from './AppStore';

interface InputsToUpdate {
    businessIdInput: InputBaseModel;
    expiringPeriodStartInput: InputBaseModel;
}

export default class BusinessSearchStore {
    modalVisible: boolean = false;
    inputsToUpdate: InputsToUpdate | null = null;
    businessPartners: BusinessPartnerDto[] = [];
    businessPartnersLoading: boolean = false;

    businesses: BusinessDto[] = [];
    businessesLoading: boolean = false;

    page: number = 0;
    pageSize: number = 10;

    readonly requestPage: number = 0;
    readonly requestPageSize: number = 1000;

    constructor(
        private readonly appStore: AppStore,
        private readonly service: ContractsIngestionService
    ) {
        makeObservable(this, {
            businessPartners: observable,
            businesses: observable,
            businessPartnersLoading: observable,
            businessesLoading: observable,
            modalVisible: observable,
            page: observable,
            pageSize: observable,
            inputsToUpdate: observable,
            setModalVisible: action,
            setBusinessPartners: action,
            setBusinesses: action,
            setInputsToUpdate: action,
            businessPartnersOptions: computed
        });

        this.getBusinessPartners();
    }

    setInputsToUpdate(inputsToUpdate: InputsToUpdate | null) {
        this.inputsToUpdate = inputsToUpdate;
    }

    updatePaging(page: number, pageSize: number) {
        runInAction(() => {
            this.page = page;
            this.pageSize = pageSize;
        });
    }

    setModalVisible(modalVisible: boolean) {
        this.modalVisible = modalVisible;
    }

    setBusinessPartners(businessPartners: BusinessPartnerDto[]) {
        this.businessPartners = businessPartners;
    }

    setBusinesses(businesses: BusinessDto[]) {
        this.businesses = businesses;
    }

    async getBusinessPartners() {
        try {
            runInAction(() => {
                this.businessPartnersLoading = true;
            });

            const resp = await this.service.searchBusinessPartners(
                this.appStore.sessionExtension,
                this.appStore.sessionId
            );

            if (resp.isErr()) {
                return;
            }

            resp.map(businessPartners => this.setBusinessPartners(businessPartners));
        } finally {
            runInAction(() => {
                this.businessPartnersLoading = false;
            });
        }
    }

    async getBusinesses(filter: Omit<BusinessRequest, 'pageNumber' | 'pageSize'>) {
        try {
            runInAction(() => {
                this.businessesLoading = true;
            });

            const resp = await this.service.searchBusinesses(this.appStore.sessionExtension, this.appStore.sessionId, {
                ...filter,
                pageNumber: this.requestPage,
                pageSize: this.requestPageSize
            });

            if (resp.isErr()) {
                message.error('Business search is currently unavailable. Please try again later');
                return;
            }

            resp.map(businesses => this.setBusinesses(businesses));
        } finally {
            runInAction(() => {
                this.businessesLoading = false;
            });
        }
    }

    get businessPartnersOptions() {
        return this.businessPartners.map(p => ({ label: p.text, value: p.value, title: p.text }));
    }
}
