import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Tooltip } from 'antd';
import { InputRenderer } from '../../components';
import { BusinessIdInput, ExpiringPeriodStartInput } from '../../node_inputs/GeneralInformationNodeInputs';
import { useAppStore } from '../../stores';
import { InputBaseModel } from '../../models';
import { ClickableIcon } from '../../../custom_shared/components';
import { SearchIcon } from '../../../custom_shared/icons';
import { StyleUtils } from '../../misc';

interface Props {
    businessIdInput: InputBaseModel;
    expiringPeriodStartInput: InputBaseModel;
    rowStyle?: React.CSSProperties;
}

const RenewalRowRenderer: React.FC<Props> = ({ businessIdInput, expiringPeriodStartInput, rowStyle }) => {
    const { businessSearchStore } = useAppStore();

    const handleBusinessSearchClick = () => {
        businessSearchStore.setInputsToUpdate({
            businessIdInput,
            expiringPeriodStartInput
        });

        businessSearchStore.setModalVisible(true);
    };

    return (
        <Row gutter={10} style={rowStyle}>
            <Col style={StyleUtils.getNodeColumnStyle(8)}>
                <InputRenderer input={businessIdInput} component={BusinessIdInput} />
            </Col>

            <Col style={StyleUtils.getNodeColumnStyle(8)}>
                <InputRenderer input={expiringPeriodStartInput} component={ExpiringPeriodStartInput} />
            </Col>

            <Col style={{ ...StyleUtils.getNodeColumnStyle(8), alignItems: 'center' }}>
                <Tooltip title="Business Search">
                    <ClickableIcon icon={SearchIcon} onClick={handleBusinessSearchClick} width={24} height={24} />
                </Tooltip>
            </Col>
        </Row>
    );
};

export default observer(RenewalRowRenderer);
