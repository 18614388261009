import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Dropdown, Menu } from 'antd';
import { ThreeDotsIcon } from '../../../custom_shared/icons';
import { ClickableIcon } from '../../../custom_shared/components';
import { useAppStore } from '../../stores';
import ReRunAnalysisMenuItem from './ReRunAnalysisMenuItem';
import ExportToJsonMenuItem from './ExportToJsonMenuItem';
import AdjustComplexityMenuItem from './AdjustComplexityMenuItem';
import RenewalSuggestionMenuItem from './RenewalSuggestionMenuItem';

const MoreActionsMenu: React.FC = () => {
    const { controlStateStore } = useAppStore();

    const [menuVisible, setMenuVisible] = React.useState(false);

    const moreActionsMenu = (
        <Menu onClick={() => setMenuVisible(false)}>
            <ReRunAnalysisMenuItem />
            <ExportToJsonMenuItem />
            <AdjustComplexityMenuItem />
            <RenewalSuggestionMenuItem />
        </Menu>
    );

    return (
        <Dropdown
            placement="bottomRight"
            visible={menuVisible}
            overlay={moreActionsMenu}
            onVisibleChange={setMenuVisible}
            trigger={['click']}
            arrow
        >
            <Tooltip title="More actions">
                <ClickableIcon
                    icon={ThreeDotsIcon}
                    onClick={() => setMenuVisible(true)}
                    disabled={controlStateStore.actionsDisabled}
                />
            </Tooltip>
        </Dropdown>
    );
};

export default observer(MoreActionsMenu);
