import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Progress } from 'antd';
import { useAppStore } from '../../stores';
import './ContractStatusModal.less';

const ContractStatusModal: React.FC = () => {
    const { contractSubmissionStore } = useAppStore();

    const ref = React.useRef<HTMLDivElement>(null);

    const getStatusLabel = () => {
        if (contractSubmissionStore.requestProcessingDelayed) {
            return (
                <span>
                    The request is still being processed. The response from the external system is taking longer than
                    expected. Thank you for your patience…
                </span>
            );
        }

        return (
            <span>
                Your request has been submitted successfully. We are now awaiting a response from the target system.
                Please note that this process may take a few minutes....
            </span>
        );
    };

    return (
        <div ref={ref} className="contract-status-modal-container">
            <Modal
                title="Processing Your Request..."
                visible={contractSubmissionStore.contractSending}
                closable={false}
                footer={null}
                getContainer={() => ref.current as HTMLElement}
                centered
            >
                {getStatusLabel()}
                <Progress strokeWidth={8} status="active" strokeColor="#273C75" percent={100} showInfo={false} />
            </Modal>
        </div>
    );
};

export default observer(ContractStatusModal);
