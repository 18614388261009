import * as React from 'react';
import { Form } from 'antd';
import { InputBaseModel, NodeBaseModel } from '../models';
import { TypeUtils } from '../../custom_shared/misc';
import { InstanceUtils, Utils, RequiredConditionsValidator } from '../misc';
import { NodeFormValues } from '../types';
import { useAppStore } from '../stores';

export default (node: NodeBaseModel) => {
    const { formStore } = useAppStore();

    const [formKey, setFormKey] = React.useState(0);

    const [form] = Form.useForm<NodeFormValues>();

    const checkNodeValidity = (nodeToCheck: NodeBaseModel): boolean => {
        const inputsValid = nodeToCheck.inputs.filter(i => i.required).every(i => i.hasValue);
        const childNodesValid = nodeToCheck.childNodes.every(childNode => checkNodeValidity(childNode));
        return inputsValid && childNodesValid;
    };

    const onValuesChange = (changedValues: NodeFormValues) => {
        Object.keys(changedValues).forEach(inputGuid => handleInputValuesChange(inputGuid));

        if (!node.hasError) {
            return;
        }

        if (checkNodeValidity(node)) {
            node.setHasError(false);
        }
    };

    const setFormFieldValue = React.useCallback(
        (input: InputBaseModel) => {
            form.setFieldsValue({
                [input.guid]: input.dataType === 'DateTime' ? Utils.convertDateValueToMoment(input.value) : input.value
            });

            RequiredConditionsValidator.validateRequiredConditions(input);
        },
        [form]
    );

    const getInputByGuid = (nodeToCheck: NodeBaseModel, guid: string): InputBaseModel | undefined => {
        const input = nodeToCheck.inputs.find(i => i.guid === guid);

        if (input) {
            return input;
        }

        for (const childNode of node.childNodes) {
            const childInput = childNode.getInputByGuid(guid);

            if (childInput) {
                return childInput;
            }
        }

        return undefined;
    };

    const resetInputValue = (inputGuid: string) => {
        const input = getInputByGuid(node, inputGuid);

        if (!input) {
            return;
        }

        const value = TypeUtils.isStringArray(form.getFieldValue(inputGuid)) ? [] : null;

        input.setValue(value);
    };

    const updateChildInputValues = (input: InputBaseModel) => {
        if (!InstanceUtils.isInputWithSource(input.childInput)) {
            return;
        }

        resetInputValue(input.childInput.guid);

        input.childInput.fetchSourceOptions(false);

        updateChildInputValues(input.childInput);
    };

    const handleInputValuesChange = (inputGuid: string) => {
        const input = getInputByGuid(node, inputGuid);

        if (!input) {
            return;
        }

        const value = form.getFieldValue(inputGuid);

        input.setValue(value);

        updateChildInputValues(input);
    };

    const setNodeFormFieldValues = React.useCallback(
        (nodeToProcess: NodeBaseModel) => {
            nodeToProcess.inputs.forEach(setFormFieldValue);

            nodeToProcess.childNodes.forEach(childNode => {
                setNodeFormFieldValues(childNode);
            });
        },
        [setFormFieldValue]
    );

    const getNodeSubscriptions = React.useCallback(
        (nodeToProcess: NodeBaseModel) => {
            const subscriptions = [
                nodeToProcess.inputValueChangeSubject.subscribe(setFormFieldValue),
                nodeToProcess.inputValidationSubject.subscribe(input => form.validateFields([input.guid]))
            ];

            nodeToProcess.childNodes.forEach(childNode => {
                subscriptions.push(...getNodeSubscriptions(childNode));
            });

            return subscriptions;
        },
        [setFormFieldValue, form]
    );

    React.useEffect(() => {
        formStore.addFormRef(node.id, form);
        return () => formStore.removeFormRef(node.id);
    }, [node, form, formStore]);

    React.useEffect(() => {
        setFormKey(prevKey => prevKey + 1);
    }, [node]);

    React.useEffect(() => {
        const subscriptions = getNodeSubscriptions(node);
        return () => subscriptions.forEach(subscription => subscription.unsubscribe());
    }, [form, node, getNodeSubscriptions]);

    React.useEffect(() => {
        setNodeFormFieldValues(node);

        node.childNodes.forEach(childNode => {
            setNodeFormFieldValues(childNode);
        });
    }, [node, node.inputs, node.childNodes, setNodeFormFieldValues]);

    return {
        form,
        formKey,
        onValuesChange
    };
};
