/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const ArrowDown: React.FC<IconProps> = ({ width = 18, height = 18, color = '#717887', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.73486 7.76471L6.26519 7.23438L9.00003 9.96921L11.7349 7.23438L12.2652 7.76471L9.00003 11.0299L5.73486 7.76471Z"
            fill={color}
        />
    </svg>
);

export default ArrowDown;
