import security from '../../common/services/SecurityService';

export default class DataCrafterService {
    async fetchOutputDocument(sessionId: string, packageId: string): Promise<Blob> {
        try {
            const url = `${process.env.REACT_APP_IOTA_URL}data-crafter/package/${sessionId}/${packageId}`;
            const blobResponse = await security.invoke(async token => {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                return await response.blob();
            });

            return blobResponse;
        } catch (error) {
            console.error('Error fetching output document:', error);
            throw error;
        }
    }
}
