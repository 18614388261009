import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button } from 'antd';
import { useAppStore } from '../../stores';
import { RenewalSuggestionsList } from '..';
import { InputIds } from '../../misc';
import './RenewalSuggestionsModal.less';

const RenewalSuggestionsModal: React.FC = () => {
    const { nodesStore, renewalSuggestionsStore, contractSubmissionStore, controlStateStore } = useAppStore();

    const onClose = () => {
        renewalSuggestionsStore.setModalVisible(false);
        renewalSuggestionsStore.disableRenewalSuggestionsSearch();
    };

    const onSubmit = () => {
        const businessIdInput = nodesStore.generalInformationNode?.inputs.find(
            i => i.id === InputIds.GeneralInformation.BusinessId
        );

        const expiringPeriodStartInput = nodesStore.generalInformationNode?.inputs.find(
            i => i.id === InputIds.GeneralInformation.ExpiringPeriodStart
        );

        if (renewalSuggestionsStore.selectedSuggestion && businessIdInput && expiringPeriodStartInput) {
            businessIdInput.setValue(renewalSuggestionsStore.selectedSuggestion.businessId);
            expiringPeriodStartInput.setValue(renewalSuggestionsStore.selectedSuggestion.startDate);
        }

        contractSubmissionStore.sendContract();

        onClose();
    };

    return (
        <Modal
            className="contract-ingestion-renewal-suggestions-modal"
            title="Renew Business"
            visible={renewalSuggestionsStore.modalVisible}
            maskClosable={false}
            closable={false}
            width={1200}
            destroyOnClose
            centered
            onCancel={onClose}
            footer={[
                <Button key="cancel-button" className="light alpha-dialog-button" onClick={onClose}>
                    Cancel
                </Button>,
                <Button
                    key="save-button"
                    type="primary"
                    onClick={onSubmit}
                    disabled={controlStateStore.actionsDisabled}
                >
                    Renew Business
                </Button>
            ]}
        >
            <div className="renewal-suggestions-explanation-container">
                <span className="explanation-title">Existing Business Detected!</span>
                <span className="explanation-text">
                    It appears that the document you uploaded already exists as a business. Do you want to renew this
                    business?
                </span>
            </div>

            <RenewalSuggestionsList />
        </Modal>
    );
};

export default observer(RenewalSuggestionsModal);
