import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import RenewalSuggestionCard from './RenewalSuggestionCard';
import './RenewalSuggestionsList.less';

const RenewalSuggestionsList: React.FC = () => {
    const { renewalSuggestionsStore } = useAppStore();

    return (
        <div className="renewal-suggestions-list-container">
            {renewalSuggestionsStore.state.suggestions.map((suggestion, index) => (
                <RenewalSuggestionCard key={`${suggestion.businessId}-${index}`} suggestion={suggestion} />
            ))}
        </div>
    );
};

export default observer(RenewalSuggestionsList);
