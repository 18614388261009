import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomInputWrapper, CustomFormItem, InputActions } from '../../components';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Business ID'
};

const BusinessIdInput: InputWithoutSource = ({ input }) => {
    return (
        <CustomFormItem name={input.guid} required={input.required}>
            <CustomInputWrapper
                input={input}
                label={config.label}
                allowClear
                topRightNode={<InputActions input={input} />}
            />
        </CustomFormItem>
    );
};

BusinessIdInput.meta = {
    inputType: InputModel.name
};

export default observer(BusinessIdInput);
