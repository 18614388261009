import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Spin } from 'antd';
import { useAppStore } from '../../stores';
import {
    AppHeader,
    NodesNavigation,
    NodesRenderer,
    DocumentPreview,
    ContractStatusModal,
    FlowResponseRenderer,
    ErrorsRenderer,
    RenewalSuggestionsModal
} from '../../components';
import { CustomDivider } from '../../../custom_shared/components';
import './ContractIngestionAppScreen.less';
import '../../../custom_shared/styles/common.less';

const ContractIngestionAppScreen: React.FC = () => {
    const appStore = useAppStore();

    const { sessionStore, documentPreviewStore } = appStore;

    React.useEffect(() => {
        sessionStore.initialize();
    }, [sessionStore]);

    React.useEffect(() => {
        return () => appStore.dispose();
    }, [appStore]);

    return (
        <div className="contract-ingestion-app-screen">
            <div className="top-container">
                <AppHeader />
                <ContractStatusModal />
                <FlowResponseRenderer />
                <RenewalSuggestionsModal />
                <ErrorsRenderer />
                <NodesNavigation />
            </div>

            <CustomDivider type="horizontal" />

            <div className={`bottom-container preview-position-${documentPreviewStore.previewPosition}`}>
                <div className={`bottom-left-container ${documentPreviewStore.fullScreenMode ? 'expanded' : ''}`}>
                    {sessionStore.isInitializing ? (
                        <div className="loading-overlay">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <NodesRenderer />
                    )}
                </div>

                {!documentPreviewStore.fullScreenMode && <CustomDivider type="vertical" />}

                <div className={`bottom-right-container ${documentPreviewStore.fullScreenMode ? 'hidden' : ''}`}>
                    <DocumentPreview />
                </div>
            </div>
        </div>
    );
};

export default observer(ContractIngestionAppScreen);
