import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { NodeProps } from '../../componentTypes';
import { NodeRendererRegistry } from '..';
import { useAppStore } from '../../stores';
import './NodeRenderer.less';

const NodeRenderer: React.FC<NodeProps> = ({ node }: NodeProps) => {
    const { nodesStore, contractSubmissionStore, sessionStore } = useAppStore();

    const Renderer = React.useMemo(() => NodeRendererRegistry[node.id], [node.id]);

    const showLoader = React.useMemo(() => {
        if (contractSubmissionStore.contractSending) {
            return false;
        }

        return sessionStore.isLoading || sessionStore.inProgress;
    }, [contractSubmissionStore.contractSending, sessionStore.inProgress, sessionStore.isLoading]);

    const getClassName = React.useCallback(() => {
        let className = 'node-renderer-container';

        if (nodesStore.activeNodeId === node.id) {
            className += ' active';
        } else {
            className += ' hidden';
        }

        if (showLoader) {
            className += ' loading';
        }

        return className;
    }, [nodesStore.activeNodeId, node.id, showLoader]);

    if (!Renderer) {
        console.error(`Node '${node.id}' is not registered`);
        return null;
    }

    return (
        <div key={node.id} className={getClassName()}>
            <Renderer node={node} />
        </div>
    );
};

export default observer(NodeRenderer);
