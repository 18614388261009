/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const FindDocumentIcon: React.FC<IconProps> = ({ width = 24, height = 24, color = 'black', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7.41406 20.4453H11.6953C11.2891 20.0938 10.9375 19.6641 10.6797 19.1875H7.48438C6.67969 19.1875 6.25 18.7734 6.25 17.9922V6.16406C6.25 5.39844 6.67188 4.95312 7.48438 4.95312H15.6484C16.4297 4.95312 16.875 5.39062 16.875 6.16406V12.9844C17.3516 13.2422 17.7812 13.5859 18.1328 14V6.14844C18.1328 4.50781 17.3281 3.69531 15.7109 3.69531H7.41406C5.80469 3.69531 4.99219 4.51562 4.99219 6.14844V17.9922C4.99219 19.6328 5.80469 20.4453 7.41406 20.4453ZM8.60938 8.14062H14.5312C14.8047 8.14062 15.0156 7.92188 15.0156 7.64844C15.0156 7.38281 14.8047 7.17969 14.5312 7.17969H8.60938C8.32031 7.17969 8.11719 7.38281 8.11719 7.64844C8.11719 7.92188 8.32031 8.14062 8.60938 8.14062ZM8.60938 10.8594H14.5312C14.8047 10.8594 15.0156 10.6484 15.0156 10.375C15.0156 10.1094 14.8047 9.89844 14.5312 9.89844H8.60938C8.32031 9.89844 8.11719 10.1094 8.11719 10.375C8.11719 10.6484 8.32031 10.8594 8.60938 10.8594ZM14.625 20.4453C15.3281 20.4453 15.9922 20.2344 16.5391 19.875L18.2969 21.6328C18.4844 21.8203 18.6562 21.8906 18.8672 21.8906C19.2266 21.8906 19.5 21.6094 19.5 21.2109C19.5 21.0391 19.4141 20.8672 19.2812 20.7344L17.5 18.9453C17.8984 18.375 18.1328 17.6875 18.1328 16.9375C18.1328 14.9844 16.5625 13.4141 14.625 13.4141C12.6875 13.4141 11.1016 15 11.1016 16.9375C11.1016 18.875 12.6875 20.4453 14.625 20.4453ZM14.625 19.3281C13.2969 19.3281 12.2188 18.25 12.2188 16.9375C12.2188 15.625 13.2969 14.5391 14.625 14.5391C15.9297 14.5391 17.0078 15.625 17.0078 16.9375C17.0078 18.25 15.9375 19.3281 14.625 19.3281Z"
            fill={color}
        />
        <path
            d="M7.41406 20.4453H11.6953C11.2891 20.0938 10.9375 19.6641 10.6797 19.1875H7.48438C6.67969 19.1875 6.25 18.7734 6.25 17.9922V6.16406C6.25 5.39844 6.67188 4.95312 7.48438 4.95312H15.6484C16.4297 4.95312 16.875 5.39062 16.875 6.16406V12.9844C17.3516 13.2422 17.7812 13.5859 18.1328 14V6.14844C18.1328 4.50781 17.3281 3.69531 15.7109 3.69531H7.41406C5.80469 3.69531 4.99219 4.51562 4.99219 6.14844V17.9922C4.99219 19.6328 5.80469 20.4453 7.41406 20.4453ZM8.60938 8.14062H14.5312C14.8047 8.14062 15.0156 7.92188 15.0156 7.64844C15.0156 7.38281 14.8047 7.17969 14.5312 7.17969H8.60938C8.32031 7.17969 8.11719 7.38281 8.11719 7.64844C8.11719 7.92188 8.32031 8.14062 8.60938 8.14062ZM8.60938 10.8594H14.5312C14.8047 10.8594 15.0156 10.6484 15.0156 10.375C15.0156 10.1094 14.8047 9.89844 14.5312 9.89844H8.60938C8.32031 9.89844 8.11719 10.1094 8.11719 10.375C8.11719 10.6484 8.32031 10.8594 8.60938 10.8594ZM14.625 20.4453C15.3281 20.4453 15.9922 20.2344 16.5391 19.875L18.2969 21.6328C18.4844 21.8203 18.6562 21.8906 18.8672 21.8906C19.2266 21.8906 19.5 21.6094 19.5 21.2109C19.5 21.0391 19.4141 20.8672 19.2812 20.7344L17.5 18.9453C17.8984 18.375 18.1328 17.6875 18.1328 16.9375C18.1328 14.9844 16.5625 13.4141 14.625 13.4141C12.6875 13.4141 11.1016 15 11.1016 16.9375C11.1016 18.875 12.6875 20.4453 14.625 20.4453ZM14.625 19.3281C13.2969 19.3281 12.2188 18.25 12.2188 16.9375C12.2188 15.625 13.2969 14.5391 14.625 14.5391C15.9297 14.5391 17.0078 15.625 17.0078 16.9375C17.0078 18.25 15.9375 19.3281 14.625 19.3281Z"
            fill={color}
            fillOpacity="0.2"
        />
    </svg>
);

export default FindDocumentIcon;
