/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const CollapseArrowDown: React.FC<IconProps> = ({ width = 9, height = 24, color = '#717887', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 9 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 10.3536L0.707107 9.64648L4.35355 13.2929L8 9.64648L8.70711 10.3536L4.35355 14.7071L0 10.3536Z"
            fill={color}
        />
    </svg>
);

export default CollapseArrowDown;
