import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, message } from 'antd';
import { useAppStore } from '../../stores';
import { useStore } from '../../../common/stores';
import { ReloadIcon } from '../../../custom_shared/icons';

const key = 're-run-analysis';

const ReRunAnalysisMenuItem: React.FC = () => {
    const { tabsStore, restricedAccessStore } = useStore();

    const appStore = useAppStore();

    const { sessionStore } = appStore;

    if (restricedAccessStore.restricedModeEnabled || !appStore.tab.metadata.isReloadable) {
        return null;
    }

    const onReload = async () => {
        const resp = await sessionStore.startSession(appStore.appId, appStore.packageId);

        if (!resp.isOk()) {
            message.error(resp.error.data ? resp.error.data.title : resp.error.text);
            return;
        }

        resp.map(({ sessionId }) =>
            tabsStore.setSessionTabReloading({
                sessionId,
                packageName: appStore.packageName,
                packageId: appStore.packageId
            })
        );

        sessionStore.updateSession(sessionStore.sessionId, 'Archived');
        appStore.dispose();
    };

    return (
        <Menu.Item key={key} eventKey={key} icon={<ReloadIcon />} onClick={onReload}>
            Re-run Analysis
        </Menu.Item>
    );
};

export default observer(ReRunAnalysisMenuItem);
