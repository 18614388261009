import { CustomAppProps } from './CustomAppRenderer';
import CustomUiDemoApp from '../custom_ui_demo_app';
import CustomBlazorApp from '../custom_blazor_app_poc';
import ContractIngestionApp from '../contract_ingestion_app';
import DataCrafterApp from '../data_crafter_app';
type Registry = Record<string, React.FC<CustomAppProps> | undefined>;

const CustomAppRegistry: Registry = {
    ['CustomUiDemoApp']: CustomUiDemoApp,
    ['ContractsIngestionApp']: ContractIngestionApp,
    ['CustomBlazorApp']: CustomBlazorApp,
    ['DataCrafterApp']: DataCrafterApp
};

export default CustomAppRegistry;
