import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { NodeProps } from '../../componentTypes';
import { ClaimBasisInput, ClaimAdviceLimitInput } from '../../node_inputs/ClaimsNodeInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils, InputIds } from '../../misc';
import { useNodeForm } from '../../hooks';

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.Claims.ClaimBasis]: ClaimBasisInput,
    [InputIds.Claims.ClaimAdviceLimit]: ClaimAdviceLimitInput
} as const;

const ClaimsNodeRenderer: React.FC<NodeProps> = ({ node }: NodeProps) => {
    const { form, formKey, onValuesChange } = useNodeForm(node);

    const getComponentRows = React.useCallback(
        (inputs: InputBaseModel[]) =>
            Utils.groupArray(inputs, 3).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        []
    );

    return (
        <LayoutRenderer
            node={node}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(ClaimsNodeRenderer);
