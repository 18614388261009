import * as React from 'react';
import moment from 'moment-timezone';
import { observer } from 'mobx-react-lite';
import { Radio } from 'antd';
import { RenewalSuggestionDto } from '../../types';
import { useAppStore } from '../../stores';
import RenewalSuggestionField from './RenewalSuggestionField';
import './RenewalSuggestionCard.less';

interface Props {
    suggestion: RenewalSuggestionDto;
}

const RenewalSuggestionCard: React.FC<Props> = ({ suggestion }: Props) => {
    const { renewalSuggestionsStore } = useAppStore();

    const renderBusinessId = () => {
        if (!suggestion.businessId) {
            return null;
        }

        return <span className="business-id">{suggestion.businessId}</span>;
    };

    const renderStartDate = () => {
        if (!suggestion.startDate) {
            return null;
        }

        return <span className="start-date">{moment(suggestion.startDate).format('DD.MM.YYYY')}</span>;
    };

    const getLimits = () => {
        const limits: string[] = [];

        if (suggestion.coverLimit) {
            limits.push(`Cover: ${suggestion.coverLimit}`);
        }

        if (suggestion.excessLimit) {
            limits.push(`Excess: ${suggestion.excessLimit}`);
        }

        if (suggestion.liabilityLimit) {
            limits.push(`Liability: ${suggestion.liabilityLimit}`);
        }

        return limits;
    };

    const isSelected = renewalSuggestionsStore.selectedSuggestion === suggestion;

    return (
        <div className={`renewal-suggestion-card-container ${isSelected ? 'selected' : ''}`}>
            <div className="radio-button-container">
                <Radio checked={isSelected} onClick={() => renewalSuggestionsStore.setSelectedSuggestion(suggestion)} />
            </div>

            <div className="content-container">
                <div className="summary-container">
                    {renderBusinessId()}
                    {renderStartDate()}
                </div>

                <div className="field-columns-container">
                    <div className="field-column">
                        <RenewalSuggestionField fieldLabel="Cedent" fieldValue={suggestion.cedentName} />
                        <RenewalSuggestionField fieldLabel="Broker" fieldValue={suggestion.brokerName} />
                    </div>

                    <div className="field-column">
                        <RenewalSuggestionField fieldLabel="Main Class" fieldValue={suggestion.mainClassesOfBusiness} />
                        <RenewalSuggestionField fieldLabel="Type" fieldValue={suggestion.typeOfParticipation} />
                    </div>

                    <div className="field-column">
                        <RenewalSuggestionField fieldLabel="Location" fieldValue={suggestion.locations} />
                        <RenewalSuggestionField
                            fieldLabel="Limits"
                            fieldValue={getLimits()}
                            fieldValuesDelimiter=";"
                            hideShowMoreOption
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(RenewalSuggestionCard);
