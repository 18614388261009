import { observable, action, reaction, makeObservable } from 'mobx';
import { Disposer } from '../../custom_shared/misc';
import { CustomLinkedSource, CustomStaticSource } from '../../custom_shared/types';
import { StaticSourceModel, LinkedSourceModel, SourceModelBase } from '../models';
import { SessionService } from '../../common/services';
import { WidgetValuesProvider } from '../../common/services/WidgetValuesProvider';
import SessionStore from './SessionStore';
import AppStore from './AppStore';

export default class SourceStore extends Disposer {
    sources: SourceModelBase[] = [];

    constructor(
        private readonly appStore: AppStore,
        private readonly sessionStore: SessionStore,
        private readonly sessionService: SessionService
    ) {
        super();

        makeObservable(this, {
            sources: observable,
            createSources: action
        });

        this.reactions.push(
            reaction(
                () => this.sessionStore.response,
                () => {
                    if (!this.sessionStore.inProgress && !this.sources.length) {
                        this.createSources();
                    }
                }
            )
        );
    }

    createSources() {
        if (!this.sessionStore.response) {
            return;
        }

        this.sources = this.sessionStore.response.sources.map(source => {
            if (!source.link) {
                return new StaticSourceModel(source as CustomStaticSource);
            }

            const widgetValuesProvider = new WidgetValuesProvider(
                this.sessionService,
                source.link,
                this.appStore.sessionId,
                this.appStore.applicationData
            );

            return new LinkedSourceModel(source as CustomLinkedSource, widgetValuesProvider);
        });
    }

    dispose() {
        this.disposeReactions();
    }
}
