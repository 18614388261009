import * as React from 'react';
import { CollapseArrowDown, CollapseArrowRight } from '../../icons';

interface Props {
    isActive: boolean;
}

const CollapseExpandIcon = ({ isActive }: Props) => (isActive ? <CollapseArrowDown /> : <CollapseArrowRight />);

export default CollapseExpandIcon;
