import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse } from 'antd';
import { CollapseExpandIcon } from '../../../custom_shared/components';
import './GeneralInformationPanel.less';

interface Props {
    panelKey: string;
    panelName: string;
    children: React.ReactNode;
    addRef: (panelKey: string, ref: React.RefObject<HTMLDivElement>) => void;
}

const GeneralInformationPanel: React.FC<Props> = (props: Props) => {
    const { panelKey, panelName, children, addRef } = props;

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        addRef(panelKey, ref);
    }, [panelKey, addRef]);

    return (
        <div ref={ref} className="general-information-panel-container">
            <Collapse bordered={false} defaultActiveKey={panelKey} expandIcon={CollapseExpandIcon}>
                <Collapse.Panel
                    className="section-collapse-panel"
                    header={<span className="header-title">{panelName}</span>}
                    key={panelKey}
                >
                    {children}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default observer(GeneralInformationPanel);
