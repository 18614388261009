import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from 'antd';
import { useAppStore } from '../../stores';
import './TemplateColumnsPreview.less';
import { DataCrafterOutputColumn } from '../../types';
const TemplateColumnsPreview: React.FC = () => {
    const { currentTemplateColumns } = useAppStore();

    const tableColumns = [
        {
            title: 'Column',
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            render: (_: string, record: DataCrafterOutputColumn) =>
                `${record.worksheetName}:${record.name}:${record.headerCoordinates}`
        },
        {
            title: 'Mapping Rule',
            dataIndex: 'mappingRule',
            key: 'mappingRule',
            width: '60%'
        }
    ];

    if (!currentTemplateColumns.length) {
        return null;
    }

    return (
        <div className="template-columns-preview">
            <Table
                className="columns-table"
                dataSource={currentTemplateColumns}
                columns={tableColumns}
                pagination={false}
                size="middle"
                bordered={false}
                rowKey={record => `${record.worksheetName}-${record.name}-${record.headerCoordinates}`}
            />
        </div>
    );
};

export default observer(TemplateColumnsPreview);
