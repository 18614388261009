import appClient from '../../common/services/AppClient';
import { CancelToken } from 'axios';
import {
    RenewalSuggestionsStateDto,
    RenewalSuggestionRequest,
    BusinessPartnerDto,
    BusinessRequest,
    BusinessDto,
    ContractSubmissionRequestStateDto
} from '../types';

export default class ContractsIngestionService {
    async getRenewalSuggestionsState(sessionExtension: string, sessionId: string) {
        const url =
            process.env.REACT_APP_IOTA_URL +
            `contracts-ingestion/renewal-suggestions-state/${sessionExtension}/${sessionId}`;

        return await appClient.get<RenewalSuggestionsStateDto>(url);
    }

    async searchRenewalSuggestions(
        sessionExtension: string,
        sessionId: string,
        suggestionsRequest: RenewalSuggestionRequest,
        cancelToken: CancelToken
    ) {
        const url =
            process.env.REACT_APP_IOTA_URL +
            `contracts-ingestion/renewal-suggestions-search/${sessionExtension}/${sessionId}`;

        return await appClient.post<RenewalSuggestionsStateDto>(url, suggestionsRequest, 'json', cancelToken);
    }

    async disableRenewalSuggestionsSearch(sessionExtension: string, sessionId: string) {
        const url =
            process.env.REACT_APP_IOTA_URL +
            `contracts-ingestion/renewal-suggestions-disable-search/${sessionExtension}/${sessionId}`;

        return await appClient.post<RenewalSuggestionsStateDto>(url);
    }

    async searchBusinessPartners(sessionExtension: string, sessionId: string) {
        const url =
            process.env.REACT_APP_IOTA_URL +
            `contracts-ingestion/search-business-partners/${sessionExtension}/${sessionId}`;

        return await appClient.post<BusinessPartnerDto[]>(url);
    }

    async searchBusinesses(sessionExtension: string, sessionId: string, request: BusinessRequest) {
        const url =
            process.env.REACT_APP_IOTA_URL + `contracts-ingestion/search-businesses/${sessionExtension}/${sessionId}`;

        return await appClient.post<BusinessDto[]>(url, request);
    }

    async getContractSubmissionRequestState(sessionExtension: string, sessionId: string) {
        const url =
            process.env.REACT_APP_IOTA_URL +
            `contracts-ingestion/contract-submission-request-state/${sessionExtension}/${sessionId}`;

        return await appClient.get<ContractSubmissionRequestStateDto>(url);
    }
}
