import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { AppStoreProvider } from './stores';
import { TabModel } from '../common/types/TabModel';
import { DataCrafterAppScreen } from './screens';

interface Props {
    tab: TabModel;
}

const DataCrafterApp: React.FC<Props> = ({ tab }) => {
    return (
        <AppStoreProvider tab={tab}>
            <DataCrafterAppScreen />
        </AppStoreProvider>
    );
};

export default observer(DataCrafterApp);
