import { SourceModelBase } from '..';
import { DataCrafterSource, DataCrafterSourceData } from '../../types';

export default class StaticSourceModel extends SourceModelBase {
    readonly data: DataCrafterSourceData[];

    constructor(data: DataCrafterSource) {
        super(data);

        this.data = data.data ?? [];
    }

    async getSourceOptions() {
        return this.mapData(this.data);
    }
}
