import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Form } from 'antd';
import {
    InputRenderer,
    GeneralInformationPanel,
    PanelSwitch,
    RenewalRowRenderer,
    RenewalPanelRenderer,
    BusinessSearchModal
} from '../../components';
import {
    TitleInput,
    CreateProgramInput,
    MainClassInput,
    ClassInput,
    SubClassInput,
    TypeOfParticipationInput,
    BusinessTypeInput,
    UnderwritingYearInput,
    PeriodStartInput,
    PeriodEndInput,
    CountryGroupsInput,
    CountriesInput,
    LifecycleInput,
    CurrencyInput,
    CedentInput,
    InsuredInput,
    BrokerInput,
    ReinsurerInput,
    ReportUnitsInput
} from '../../node_inputs/GeneralInformationNodeInputs';
import { NodeProps } from '../../componentTypes';
import { InstanceUtils, StyleUtils, InputIds, ChildNodeIds } from '../../misc';
import { useNodeForm } from '../../hooks';
import './GeneralInformationNodeRenderer.less';

const Panels = {
    contractRenewal: {
        key: ChildNodeIds.GeneralInformation.ContractRenewal,
        label: 'Contract Renewal'
    },
    contractStructure: {
        key: 'ContractStructure',
        label: 'Contract Structure'
    },
    period: {
        key: 'Period',
        label: 'Period'
    },
    coverageClassification: {
        key: 'CoverageClassification',
        label: 'Coverage Classification'
    },
    partners: {
        key: 'Partners',
        label: 'Partners'
    }
} as const;

const GeneralInformationNodeRenderer: React.FC<NodeProps> = ({ node }: NodeProps) => {
    const { form, formKey, onValuesChange } = useNodeForm(node);

    const panelRefs = React.useRef<Map<string, React.RefObject<HTMLDivElement>>>(new Map());

    const addRef = React.useCallback((panelKey: string, panelRef: React.RefObject<HTMLDivElement>) => {
        panelRefs.current.set(panelKey, panelRef);
    }, []);

    const getInputById = React.useCallback((id: string) => node.inputs.find(input => input.id === id), [node.inputs]);

    const inputs = React.useMemo(
        () => ({
            titleInput: getInputById(InputIds.GeneralInformation.Title),
            createProgramInput: getInputById(InputIds.GeneralInformation.CreateProgram),
            mainClassInput: getInputById(InputIds.GeneralInformation.MainClass),
            classInput: getInputById(InputIds.GeneralInformation.Class),
            subClassInput: getInputById(InputIds.GeneralInformation.SubClass),
            businessTypeInput: getInputById(InputIds.GeneralInformation.BusinessType),
            typeOfParticipationInput: getInputById(InputIds.GeneralInformation.TypeOfParticipation),
            underwritingYearInput: getInputById(InputIds.GeneralInformation.UnderwritingYear),
            periodStartInput: getInputById(InputIds.GeneralInformation.PeriodStart),
            periodEndInput: getInputById(InputIds.GeneralInformation.PeriodEnd),
            countryGroupsInput: getInputById(InputIds.GeneralInformation.CountryGroups),
            countriesInput: getInputById(InputIds.GeneralInformation.Countries),
            lifecycleInput: getInputById(InputIds.GeneralInformation.Lifecycle),
            currencyInput: getInputById(InputIds.GeneralInformation.Currency),
            insuredInput: getInputById(InputIds.GeneralInformation.Insured),
            reinsurerInput: getInputById(InputIds.GeneralInformation.Reinsurer),
            reportUnitsInput: getInputById(InputIds.GeneralInformation.ReportUnits),
            cedentInput: getInputById(InputIds.GeneralInformation.Cedent),
            brokerInput: getInputById(InputIds.GeneralInformation.Broker),
            businessIdInput: getInputById(InputIds.GeneralInformation.BusinessId),
            expiringPeriodStartInput: getInputById(InputIds.GeneralInformation.ExpiringPeriodStart)
        }),
        [getInputById]
    );

    const hasClassification = React.useMemo(
        () => !!(inputs.mainClassInput || inputs.classInput || inputs.subClassInput),
        [inputs.classInput, inputs.mainClassInput, inputs.subClassInput]
    );

    const hasCountries = React.useMemo(
        () => !!(inputs.countriesInput || inputs.countryGroupsInput),
        [inputs.countriesInput, inputs.countryGroupsInput]
    );

    const renderCoverageClassification = React.useMemo(
        () => hasClassification || hasCountries,
        [hasClassification, hasCountries]
    );

    const contractRenewalNode = React.useMemo(
        () => node.childNodes.find(t => t.id === ChildNodeIds.GeneralInformation.ContractRenewal),
        [node.childNodes]
    );

    const panelSwitchOptions = React.useMemo(
        () =>
            Object.values(Panels)
                .filter(panel => {
                    switch (panel.key) {
                        case 'CoverageClassification':
                            return renderCoverageClassification;
                        default:
                            return true;
                    }
                })
                .map(panel => ({
                    id: panel.key,
                    label: panel.label
                })),
        [renderCoverageClassification]
    );

    const onPanelSwitchClick = (panelKey: string) => {
        const panelRef = panelRefs.current.get(panelKey);

        if (panelRef && panelRef.current) {
            panelRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    if (!InstanceUtils.isBasicNode(node)) {
        return null;
    }

    return (
        <>
            <BusinessSearchModal />

            <PanelSwitch options={panelSwitchOptions} onClick={onPanelSwitchClick} />

            <div className="general-information-node-renderer custom-scroll">
                <Form id={node.id} key={formKey} form={form} onValuesChange={onValuesChange}>
                    <div className="panels-container">
                        {contractRenewalNode && (
                            <RenewalPanelRenderer
                                node={contractRenewalNode}
                                panelName={Panels.contractRenewal.label}
                                panelKey={Panels.contractRenewal.key}
                                addRef={addRef}
                            />
                        )}

                        {inputs.businessIdInput && inputs.expiringPeriodStartInput && (
                            <GeneralInformationPanel
                                panelKey={Panels.contractRenewal.key}
                                panelName={Panels.contractRenewal.label}
                                addRef={addRef}
                            >
                                <RenewalRowRenderer
                                    businessIdInput={inputs.businessIdInput}
                                    expiringPeriodStartInput={inputs.expiringPeriodStartInput}
                                />
                            </GeneralInformationPanel>
                        )}

                        <GeneralInformationPanel
                            panelKey={Panels.contractStructure.key}
                            panelName={Panels.contractStructure.label}
                            addRef={addRef}
                        >
                            <Row gutter={10}>
                                <Col style={StyleUtils.getNodeColumnStyle(16)}>
                                    <InputRenderer input={inputs.titleInput} component={TitleInput} />
                                </Col>

                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                    <InputRenderer input={inputs.createProgramInput} component={CreateProgramInput} />
                                </Col>
                            </Row>

                            <Row gutter={10}>
                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                    <InputRenderer input={inputs.businessTypeInput} component={BusinessTypeInput} />
                                </Col>

                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                    <InputRenderer
                                        input={inputs.typeOfParticipationInput}
                                        component={TypeOfParticipationInput}
                                    />
                                </Col>

                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                    <InputRenderer input={inputs.lifecycleInput} component={LifecycleInput} />
                                </Col>
                            </Row>

                            <Row gutter={10}>
                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                    <InputRenderer input={inputs.currencyInput} component={CurrencyInput} />
                                </Col>

                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                    <InputRenderer input={inputs.reportUnitsInput} component={ReportUnitsInput} />
                                </Col>
                            </Row>
                        </GeneralInformationPanel>

                        <GeneralInformationPanel
                            panelKey={Panels.period.key}
                            panelName={Panels.period.label}
                            addRef={addRef}
                        >
                            <Row gutter={10}>
                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                    <InputRenderer
                                        input={inputs.underwritingYearInput}
                                        component={UnderwritingYearInput}
                                    />
                                </Col>

                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                    <InputRenderer input={inputs.periodStartInput} component={PeriodStartInput} />
                                </Col>

                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                    <InputRenderer input={inputs.periodEndInput} component={PeriodEndInput} />
                                </Col>
                            </Row>
                        </GeneralInformationPanel>

                        {renderCoverageClassification && (
                            <GeneralInformationPanel
                                panelKey={Panels.coverageClassification.key}
                                panelName={Panels.coverageClassification.label}
                                addRef={addRef}
                            >
                                {hasClassification && (
                                    <>
                                        <Row gutter={10}>
                                            <Col style={StyleUtils.getNodeColumnStyle(16)}>
                                                <InputRenderer
                                                    input={inputs.mainClassInput}
                                                    component={MainClassInput}
                                                />
                                            </Col>
                                        </Row>

                                        <Row gutter={10}>
                                            <Col style={StyleUtils.getNodeColumnStyle(24)}>
                                                <InputRenderer input={inputs.classInput} component={ClassInput} />
                                            </Col>
                                        </Row>

                                        <Row gutter={10}>
                                            <Col style={StyleUtils.getNodeColumnStyle(16)}>
                                                <InputRenderer input={inputs.subClassInput} component={SubClassInput} />
                                            </Col>

                                            {inputs.countryGroupsInput && (
                                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                                    <InputRenderer
                                                        input={inputs.countryGroupsInput}
                                                        component={CountryGroupsInput}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                )}

                                {inputs.countriesInput && (
                                    <Row gutter={10}>
                                        <Col style={StyleUtils.getNodeColumnStyle(24)}>
                                            <InputRenderer input={inputs.countriesInput} component={CountriesInput} />
                                        </Col>
                                    </Row>
                                )}
                            </GeneralInformationPanel>
                        )}

                        <GeneralInformationPanel
                            panelKey={Panels.partners.key}
                            panelName={Panels.partners.label}
                            addRef={addRef}
                        >
                            <Row gutter={[10, 10]} wrap>
                                {inputs.cedentInput && (
                                    <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                        <InputRenderer input={inputs.cedentInput} component={CedentInput} />
                                    </Col>
                                )}

                                {inputs.brokerInput && (
                                    <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                        <InputRenderer input={inputs.brokerInput} component={BrokerInput} />
                                    </Col>
                                )}

                                {inputs.insuredInput && (
                                    <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                        <InputRenderer input={inputs.insuredInput} component={InsuredInput} />
                                    </Col>
                                )}

                                <Col style={StyleUtils.getNodeColumnStyle(8)}>
                                    <InputRenderer input={inputs.reinsurerInput} component={ReinsurerInput} />
                                </Col>
                            </Row>
                        </GeneralInformationPanel>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default observer(GeneralInformationNodeRenderer);
