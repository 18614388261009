import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Tooltip } from 'antd';
import { ContractStatus, RenewalRowRenderer } from '..';
import { InputIds } from '../../misc';
import { LayerModel } from '../../models';
import { useMemoizedInput } from '../../hooks';
import './RenewalLayerRenderer.less';

interface Props {
    layer: LayerModel;
}

const RenewalLayerRenderer: React.FC<Props> = ({ layer }) => {
    const businessIdInput = useMemoizedInput(InputIds.GeneralInformation.BusinessId, layer.inputs);
    const expiringPeriodStartInput = useMemoizedInput(InputIds.GeneralInformation.ExpiringPeriodStart, layer.inputs);

    if (!businessIdInput || !expiringPeriodStartInput) {
        return null;
    }

    return (
        <div className="renewal-layer-container" key={layer.id}>
            <div className="layer-header">
                <span className="layer-label">{layer.name}</span>
                {!layer.layerConfiguration?.skipSubmission && (
                    <ContractStatus
                        businessIdInput={businessIdInput}
                        expiringPeriodStartInput={expiringPeriodStartInput}
                    />
                )}
            </div>

            <div className="layer-content">
                {layer.layerConfiguration && (
                    <Tooltip title="Submit layer's data" placement="topRight">
                        <Checkbox
                            checked={!layer.layerConfiguration.skipSubmission}
                            onClick={layer.layerConfiguration.toggleSkipSubmission}
                        />
                    </Tooltip>
                )}

                <RenewalRowRenderer
                    businessIdInput={businessIdInput}
                    expiringPeriodStartInput={expiringPeriodStartInput}
                    rowStyle={{ flex: 1 }}
                />
            </div>
        </div>
    );
};

export default observer(RenewalLayerRenderer);
