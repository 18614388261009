import { CustomSessionRuntimeData } from '../../custom_shared/types';
import NodesStore from './NodesStore';
import SettingsStore from './SettingsStore';

export default class RuntimeDataStore {
    static RuntimeDataKeys = {
        ActionParams: 'ActionParams',
        LayoutSettings: 'LayoutSettings'
    };

    constructor(
        private readonly nodesStore: NodesStore,
        private readonly settingStore: SettingsStore
    ) {}

    getRuntimeData(actionParams: Record<string, unknown> = {}): CustomSessionRuntimeData {
        const nodesRuntimeData = this.nodesStore.nodes.reduce<CustomSessionRuntimeData>((acc, node) => {
            acc[node.id] = { value: JSON.stringify(node.dto) };
            return acc;
        }, {});

        const additionalRuntimeData = Object.entries(actionParams).reduce<CustomSessionRuntimeData>(
            (acc, [key, value]) => {
                acc[key] = { value };
                return acc;
            },
            {}
        );

        const settings = {
            [RuntimeDataStore.RuntimeDataKeys.LayoutSettings]: {
                value: this.settingStore.layoutSettings ? JSON.stringify(this.settingStore.layoutSettings.dto) : null
            }
        };

        return {
            ...nodesRuntimeData,
            ...additionalRuntimeData,
            ...settings
        };
    }
}
