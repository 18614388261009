import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from 'antd';
import { FileOutlined, BranchesOutlined } from '@ant-design/icons';
import './OutputPreview.less';
import { DocumentPreview, TemplateColumnsPreview } from '../';
import { useAppStore } from '../../stores';
const { TabPane } = Tabs;

const OutputPreview: React.FC = () => {
    const { currentTemplateId, sessionId, sessionStore } = useAppStore();

    return (
        <div className="output-preview">
            <Tabs defaultActiveKey="template-rules" animated={false}>
                <TabPane
                    tab={
                        <>
                            <FileOutlined /> File Preview
                        </>
                    }
                    key="file-preview"
                >
                    {sessionStore.processedPackageId && (
                        <DocumentPreview
                            packageId={sessionStore.processedPackageId}
                            packageName={sessionStore.processedPackageId + '.xlsx'}
                            packageUrl={`${process.env.REACT_APP_IOTA_URL}data-crafter/package/${sessionId}/${sessionStore.processedPackageId}`}
                            sheetsbar
                        />
                    )}
                </TabPane>
                <TabPane
                    tab={
                        <>
                            <BranchesOutlined /> Mapping Rules
                        </>
                    }
                    key="used-rules"
                    disabled={!currentTemplateId}
                >
                    <TemplateColumnsPreview />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default observer(OutputPreview);
