type ColumnSpan = 24 | 16 | 12 | 8;

export default class StyleUtils {
    static columnSpanPercentage: Record<ColumnSpan, number> = {
        24: 100,
        16: 66.66,
        12: 50,
        8: 33.33
    };

    static getNodeColumnStyle(span: ColumnSpan): React.CSSProperties {
        const percentage = StyleUtils.columnSpanPercentage[span];
        return {
            display: 'flex',
            flex: `${percentage}%`,
            maxWidth: `${percentage}%`
        };
    }
}
