import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { LayersWithSectionsNodeModel } from '../../models';
import { PanelSwitch } from '../../components';
import { LayoutRendererProps } from '../../componentTypes';
import LayerWithSectionsRenderer from './LayerWithSectionsRenderer';
import './LayersWithSectionsLayoutRenderer.less';

interface Props extends LayoutRendererProps {
    node: LayersWithSectionsNodeModel;
}

const LayersWithSectionsLayoutRenderer: React.FC<Props> = (props: Props) => {
    const { node, formKey, form, onValuesChange, getComponentRows } = props;

    const layerRefs = React.useRef<Map<string, React.RefObject<HTMLDivElement>>>(new Map());

    const addLayerRef = React.useCallback((layerId: string, layerRef: React.RefObject<HTMLDivElement>) => {
        layerRefs.current.set(layerId, layerRef);
    }, []);

    const onPanelSwitchClick = (layerId: string) => {
        const layerRef = layerRefs.current.get(layerId);

        if (layerRef && layerRef.current) {
            layerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <>
            <PanelSwitch
                options={node.layers.map(layer => ({ id: layer.id, label: layer.name }))}
                onClick={onPanelSwitchClick}
            />

            <div className="layers-with-sections-layout-renderer-container custom-scroll">
                <Form id={node.id} key={formKey} form={form} onValuesChange={onValuesChange}>
                    <div className="layers-with-sections-layout-content">
                        {node.layers.map(layer => (
                            <LayerWithSectionsRenderer
                                key={layer.id}
                                node={node}
                                layer={layer}
                                getComponentRows={getComponentRows}
                                addLayerRef={addLayerRef}
                            />
                        ))}
                    </div>
                </Form>
            </div>
        </>
    );
};

export default observer(LayersWithSectionsLayoutRenderer);
