import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { GenericUnlimitedInput } from '../../components';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Liability'
};

const LiabilityInput: InputWithoutSource = ({ input }) => <GenericUnlimitedInput label={config.label} input={input} />;

LiabilityInput.meta = {
    inputType: InputModel.name
};

export default observer(LiabilityInput);
