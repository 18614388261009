import { TabModel } from '../../common/types/TabModel';
import { RootStore } from '../../common/stores/context';
import { DocumentVisualStore } from '../../documents/stores';
import { DocumentService, SessionService, ProjectsService } from '../../common/services';
import { ContractsIngestionService } from '../services';
import { TabApplicationData } from '../../common/services/types';
import { Disposer } from '../../custom_shared/misc';
import SessionStore from './SessionStore';
import SourceStore from './SourceStore';
import NodesStore from './NodesStore';
import InputStore from './InputStore';
import ActionStore from './ActionStore';
import SettingsStore from './SettingsStore';
import DocumentPreviewStore from './DocumentPreviewStore';
import BusinessSearchStore from './BusinessSearchStore';
import ContractSubmissionStore from './ContractSubmissionStore';
import RuntimeDataStore from './RuntimeDataStore';
import RenewalSuggestionsStore from './RenewalSuggestionsStore';
import FormStore from './FormStore';
import ControlStateStore from './ControlStateStore';
import ImageService from '../../common/services/ImageService';

export default class AppStore extends Disposer {
    readonly formStore: FormStore;
    readonly sessionStore: SessionStore;
    readonly sourceStore: SourceStore;
    readonly nodesStore: NodesStore;
    readonly inputStore: InputStore;
    readonly actionStore: ActionStore;
    readonly settingsStore: SettingsStore;
    readonly documentPreviewStore: DocumentPreviewStore;
    readonly documentVisualStore: DocumentVisualStore;
    readonly contractSubmissionStore: ContractSubmissionStore;
    readonly runtimeDataStore: RuntimeDataStore;
    readonly businessSearchStore: BusinessSearchStore;
    readonly renewalSuggestionsStore: RenewalSuggestionsStore;
    readonly controlStateStore: ControlStateStore;

    constructor(
        readonly tab: TabModel,
        readonly rootStore: RootStore
    ) {
        super();

        const contractsIngestionService = new ContractsIngestionService();

        this.formStore = new FormStore();

        this.sessionStore = new SessionStore(this.tab, this.rootStore.iotaSessionStore);

        this.sourceStore = new SourceStore(this, this.sessionStore, new SessionService());

        this.nodesStore = new NodesStore(this.sessionStore, this.sourceStore);

        this.inputStore = new InputStore(this.nodesStore);

        this.settingsStore = new SettingsStore(this.sessionStore);

        this.runtimeDataStore = new RuntimeDataStore(this.nodesStore, this.settingsStore);

        this.actionStore = new ActionStore(this, this.sessionStore, this.runtimeDataStore);

        this.contractSubmissionStore = new ContractSubmissionStore(
            this.formStore,
            this.nodesStore,
            this.sessionStore,
            this.actionStore,
            contractsIngestionService
        );

        this.documentVisualStore = new DocumentVisualStore(
            this.rootStore.applicationDefinitionsStore,
            new ImageService(),
            this.rootStore.errorStore,
            new DocumentService()
        );

        this.documentPreviewStore = new DocumentPreviewStore(
            this,
            this.inputStore,
            this.documentVisualStore,
            new ProjectsService()
        );

        this.businessSearchStore = new BusinessSearchStore(this, contractsIngestionService);

        this.renewalSuggestionsStore = new RenewalSuggestionsStore(
            this,
            this.nodesStore,
            this.sessionStore,
            this.contractSubmissionStore,
            this.settingsStore,
            contractsIngestionService
        );

        this.controlStateStore = new ControlStateStore(
            this.inputStore,
            this.sessionStore,
            this.contractSubmissionStore
        );
    }

    get appId() {
        return this.tab.metadata.applicationData.appId as string;
    }

    get appName() {
        return this.tab.metadata.applicationData.appName as string;
    }

    get sessionId() {
        return this.tab.metadata.sessionId as string;
    }

    get sessionExtension() {
        return this.tab.metadata.applicationData.appExtension as string;
    }

    get projectId() {
        return this.tab.metadata.projectId as string;
    }

    get packageId() {
        return this.tab.metadata.packageId as string;
    }

    get packageName() {
        return this.tab.metadata.packageName as string;
    }

    get applicationData() {
        return this.tab.metadata.applicationData as TabApplicationData;
    }

    removeAppTab() {
        const tabIndex = this.rootStore.tabsStore.tabs.indexOf(this.tab);
        this.rootStore.tabsStore.removeTab(tabIndex);
    }

    dispose() {
        this.sourceStore.dispose();
        this.nodesStore.dispose();
        this.inputStore.dispose();
        this.settingsStore.dispose();
        this.actionStore.dispose();
        this.documentPreviewStore.dispose();
        this.contractSubmissionStore.dispose();
        this.renewalSuggestionsStore.dispose();
        this.removeAppTab();
    }
}
