import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InstanceUtils } from '../../misc';
import { LayoutRendererProps } from '../../componentTypes';
import {
    BasicLayoutRenderer,
    LayersLayoutRenderer,
    LayersWithSectionsLayoutRenderer,
    SectionsLayoutRenderer
} from '..';

const LayoutRenderer: React.FC<LayoutRendererProps> = ({ node, ...rest }: LayoutRendererProps) => {
    if (InstanceUtils.isBasicNode(node)) {
        return <BasicLayoutRenderer node={node} {...rest} />;
    }

    if (InstanceUtils.isLayersNode(node)) {
        return <LayersLayoutRenderer node={node} {...rest} />;
    }

    if (InstanceUtils.isLayersWithSectionsNode(node)) {
        return <LayersWithSectionsLayoutRenderer node={node} {...rest} />;
    }

    if (InstanceUtils.isSectionsNode(node)) {
        return <SectionsLayoutRenderer node={node} {...rest} />;
    }

    console.error(`Layout renderer for node type '${node.type}' is not registered`);
    return null;
};

export default observer(LayoutRenderer);
