import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form } from 'antd';
import { useAppStore } from '../../stores';
import { DataCrafterDropdown } from '../';
import './DataCrafterForm.less';

const INPUT_DATA_CRAFTER_TEMPLATE = 'DataCrafterTemplate';

const DataCrafterForm: React.FC = () => {
    const { sessionStore } = useAppStore();

    const [form] = Form.useForm();

    React.useEffect(() => {
        sessionStore.setCurrentTemplateId(sessionStore.response?.dataCrafterTemplateId ?? undefined, false);
        form.setFieldsValue({
            [INPUT_DATA_CRAFTER_TEMPLATE]: sessionStore.response?.dataCrafterTemplateId ?? undefined
        });
    }, [sessionStore.response?.dataCrafterTemplateId, form, sessionStore]);

    return (
        <Form
            form={form}
            layout="vertical"
            onValuesChange={values => {
                sessionStore.setCurrentTemplateId(values[INPUT_DATA_CRAFTER_TEMPLATE]);
            }}
        >
            <div className="data-crafter-form">
                {/* Currently expect only dropdowns */}
                {sessionStore.response?.inputs.map(input => (
                    <DataCrafterDropdown
                        key={input.id}
                        input={input}
                        placeholder="Select template"
                        initialValue={sessionStore.currentTemplateId}
                    />
                ))}
                <Button
                    className="data-crafter-form-button"
                    type="primary"
                    onClick={sessionStore.fetchOutputDocument}
                    disabled={!sessionStore.processedPackageId}
                >
                    Download Output
                </Button>
            </div>
        </Form>
    );
};

export default observer(DataCrafterForm);
