import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomMaskedInputWrapper, InputActions, CustomFormItem } from '..';
import { InputModel } from '../../models';
import { InputWithMeta } from '../../componentTypes';
import { Utils, FieldValidators } from '../../misc';

// Please note that this is a generic component if behavior for your input is different - create a new independent component (like BusinessTypeInput.tsx etc.)

interface Props {
    label: string;
    input: InputModel;
}

const GenericUnlimitedInput: InputWithMeta<Props> = ({ label, input }) => {
    React.useEffect(() => {
        if (FieldValidators.isInvalidUnlimitedFieldValue(input.value)) {
            input.setValue(null);
        }
    }, [input]);

    return (
        <CustomFormItem
            name={input.guid}
            customValidator={(...args) => FieldValidators.unlimitedFieldValidator(...args)}
            getValueFromEvent={event => event.target.value.replace(/,/g, '')}
            messageStyle={{ width: 305 }}
        >
            <CustomMaskedInputWrapper
                input={input}
                label={label}
                topRightNode={<InputActions input={input} />}
                reformat={params => Utils.reformatMaskedInput(params)}
            />
        </CustomFormItem>
    );
};

GenericUnlimitedInput.meta = {
    inputType: InputModel.name
};

export default observer(GenericUnlimitedInput);
