import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { NodeBaseModel } from '../../models';
import { ExclamationCircleIcon } from '../../../custom_shared/icons';

interface Props {
    node: NodeBaseModel;
}

const NodeTitle: React.FC<Props> = ({ node }) => {
    return (
        <div className="node-title">
            <div className="icon-container">{node.hasError ? <ExclamationCircleIcon /> : null}</div>
            <span className="node-name">{node.name}</span>
        </div>
    );
};

export default observer(NodeTitle);
