import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse, Row, Col } from 'antd';
import { StyleUtils } from '../../misc';
import { LayersWithSectionsNodeModel, LayerWithSectionsModel, SectionModel } from '../../models';
import { GetComponentRowsFunction } from '../../componentTypes';
import { CollapseExpandIcon } from '../../../custom_shared/components';
import './SectionRenderer.less';

const mainSectionId = 'MainSection';

interface Props {
    node: LayersWithSectionsNodeModel;
    layer: LayerWithSectionsModel;
    section: SectionModel;
    getComponentRows: GetComponentRowsFunction;
}

const SectionRenderer: React.FC<Props> = (props: Props) => {
    const { node, layer, section, getComponentRows } = props;

    const ref = React.useRef<HTMLDivElement>(null);

    const getClassName = () => {
        let className = 'layers-with-sections-layout-section-renderer-container';

        if (section.id === mainSectionId) {
            className += ' main-section';
        }

        return className;
    };

    return (
        <div ref={ref} className={getClassName()}>
            <Collapse
                key={section.id}
                activeKey={section.expanded ? [section.id] : []}
                onChange={section.toggleExpanded}
                bordered={false}
                expandIcon={CollapseExpandIcon}
            >
                <Collapse.Panel
                    className="section-collapse-panel"
                    header={<span className="header-title">{section.name}</span>}
                    key={section.id}
                >
                    {getComponentRows(section.inputs, {
                        NodeId: node.id,
                        LayerId: layer.id,
                        SectionId: section.id
                    }).map((componentRow, index) => (
                        <Row key={index} gutter={10}>
                            {componentRow.map(component => (
                                <Col key={component.key} style={StyleUtils.getNodeColumnStyle(8)}>
                                    {component}
                                </Col>
                            ))}
                        </Row>
                    ))}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default observer(SectionRenderer);
