import { NodeBaseModel, InputModel, InputModelWithSource, SourceModelBase } from '../models';
import { ContractsIngestionRuntimeInputDto } from '../types';

export default class InputFactory {
    static createInputs(node: NodeBaseModel, inputs: ContractsIngestionRuntimeInputDto[], sources: SourceModelBase[]) {
        return inputs.map(input => InputFactory.createInput(node, input, sources));
    }

    static createInput(node: NodeBaseModel, input: ContractsIngestionRuntimeInputDto, sources: SourceModelBase[]) {
        if (input.sourceId) {
            return new InputModelWithSource(
                node,
                input,
                sources.find(s => s.id === input.sourceId)
            );
        }

        return new InputModel(node, input);
    }

    static createOrUpdateInputs(
        node: NodeBaseModel,
        existingInputs: InputModel[],
        inputs: ContractsIngestionRuntimeInputDto[],
        sources: SourceModelBase[]
    ) {
        for (const input of inputs) {
            const existingInput = existingInputs.find(i => i.id === input.id);

            if (existingInput) {
                existingInput.update(input);
            } else {
                existingInputs.push(InputFactory.createInput(node, input, sources));
            }
        }

        return existingInputs.filter(input => inputs.some(i => i.id === input.id));
    }
}
