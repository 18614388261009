/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const CollapseArrowRight: React.FC<IconProps> = ({ width = 9, height = 24, color = '#717887', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 9 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.53035 16.5303L1.82324 15.8232L5.46969 12.1768L1.82324 8.53033L2.53035 7.82323L6.8839 12.1768L2.53035 16.5303Z"
            fill={color}
        />
    </svg>
);

export default CollapseArrowRight;
