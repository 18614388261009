import React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import './ContractStatus.less';

const contractStatuses = {
    newBusiness: 'New Business',
    businessRenewal: 'Business Renewal',
    incompleteBusinessRenewal: 'Incomplete Business Renewal'
} as const;

interface Props {
    businessIdInput: InputBaseModel;
    expiringPeriodStartInput: InputBaseModel;
}

const ContractStatus: React.FC<Props> = ({ businessIdInput, expiringPeriodStartInput }) => {
    const status = React.useMemo(() => {
        const hasBusinessId = businessIdInput?.hasValue;
        const hasExpiringPeriod = expiringPeriodStartInput?.hasValue;

        if (!hasBusinessId && !hasExpiringPeriod) {
            return contractStatuses.newBusiness;
        }

        if (hasBusinessId && hasExpiringPeriod) {
            return contractStatuses.businessRenewal;
        }

        return contractStatuses.incompleteBusinessRenewal;
    }, [businessIdInput?.hasValue, expiringPeriodStartInput?.hasValue]);

    const statusClass = {
        [contractStatuses.newBusiness]: 'gray',
        [contractStatuses.businessRenewal]: 'blue',
        [contractStatuses.incompleteBusinessRenewal]: 'orange'
    }[status];

    return (
        <div className={`contract-status-container ${statusClass}`}>
            <span>{status}</span>
        </div>
    );
};

export default observer(ContractStatus);
