import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse, Row, Col, Form } from 'antd';
import { StyleUtils } from '../../misc';
import { BasicNodeModel } from '../../models';
import { LayoutRendererProps } from '../../componentTypes';
import { CollapseExpandIcon } from '../../../custom_shared/components';
import './BasicLayoutRenderer.less';

interface Props extends LayoutRendererProps {
    node: BasicNodeModel;
}

const BasicLayoutRenderer: React.FC<Props> = (props: Props) => {
    const { node, formKey, form, onValuesChange, getComponentRows } = props;

    return (
        <div className="basic-layout-renderer-container custom-scroll">
            <Form id={node.id} key={formKey} form={form} onValuesChange={onValuesChange}>
                <Collapse
                    activeKey={node.expanded ? [node.id] : []}
                    onChange={node.toggleExpanded}
                    bordered={false}
                    expandIcon={CollapseExpandIcon}
                >
                    <Collapse.Panel
                        className="node-collapse-panel"
                        header={<span className="header-title">{node.name}</span>}
                        key={node.id}
                    >
                        {getComponentRows(node.inputs, { NodeId: node.id }).map((componentRow, index) => (
                            <Row key={index} gutter={10}>
                                {componentRow.map(component => (
                                    <Col key={component.key} style={StyleUtils.getNodeColumnStyle(8)}>
                                        {component}
                                    </Col>
                                ))}
                            </Row>
                        ))}
                    </Collapse.Panel>
                </Collapse>
            </Form>
        </div>
    );
};

export default observer(BasicLayoutRenderer);
