import { makeObservable, observable, action } from 'mobx';
import { FormInstance } from 'antd';

export default class FormStore {
    formRefs: Map<string, FormInstance> = new Map();

    constructor() {
        makeObservable(this, {
            formRefs: observable,
            addFormRef: action,
            removeFormRef: action
        });
    }

    addFormRef(id: string, form: FormInstance) {
        this.formRefs.set(id, form);
    }

    removeFormRef(id: string) {
        this.formRefs.delete(id);
    }

    async validateForms() {
        const validations = Array.from(this.formRefs.entries()).map(async ([nodeId, form]) => {
            try {
                const values = await form.validateFields();
                return { nodeId, success: true, values };
            } catch (error) {
                return { nodeId, success: false, error };
            }
        });

        return await Promise.all(validations);
    }
}
