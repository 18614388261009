import * as React from 'react';
import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useSelectedElementStatus, useDropdownTextSelection, useDropdownOpenState } from '../../hooks';
import { CustomInputLabel } from '..';
import { ArrowDown } from '../../icons';
import './CustomSelect.less';

type CustomProps = {
    label: string;
    required?: boolean;
    topRightNode?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    hasError?: boolean;
    getContainerRef?: (containerRef: React.RefObject<HTMLDivElement>) => void;
};

export type CustomSelectProps = CustomProps & Omit<SelectProps<SelectValue>, 'mode' | 'placeholder'>;

const CustomSelect: React.FC<CustomSelectProps> = (props: CustomSelectProps) => {
    const {
        open,
        label,
        required,
        topRightNode,
        containerStyle,
        hasError,
        getContainerRef,
        onPopupScroll,
        onMouseDown,
        onDropdownVisibleChange,
        ...selectProps
    } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const { hasSelectedElement } = useSelectedElementStatus(containerRef, '.ant-select-selection-item');

    const { clearTextSelection, handleTextSelection, renderTextSelectionButton } =
        useDropdownTextSelection(containerRef);

    const { isDropdownOpen, setIsDropdownOpen } = useDropdownOpenState(open);

    React.useEffect(() => {
        if (getContainerRef) {
            getContainerRef(containerRef);
        }
    }, [getContainerRef]);

    const getContainerClassName = () => {
        let className = 'custom-select-container';

        if (hasError) {
            className += ' has-error';
        }

        return className;
    };

    return (
        <div
            ref={containerRef}
            className={getContainerClassName()}
            style={containerStyle}
            onContextMenu={event => event.preventDefault()}
        >
            {!!topRightNode && <div className="top-right-node-container">{topRightNode}</div>}

            <Select
                open={isDropdownOpen}
                className={`custom-select ${hasSelectedElement ? 'not-empty' : ''}`}
                suffixIcon={<ArrowDown />}
                dropdownClassName="custom-select-dropdown"
                onDropdownVisibleChange={isOpen => {
                    setIsDropdownOpen(isOpen);
                    onDropdownVisibleChange?.(isOpen);
                    clearTextSelection();
                }}
                onMouseDown={event => {
                    onMouseDown?.(event);
                    handleTextSelection(event);
                }}
                onPopupScroll={event => {
                    onPopupScroll?.(event);
                    clearTextSelection();
                }}
                {...selectProps}
            />

            {renderTextSelectionButton()}

            <CustomInputLabel label={label} required={required} />
        </div>
    );
};

export default CustomSelect;
