import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { first } from 'lodash';
import { Disposer } from '../../custom_shared/misc';
import { NodeBaseModel } from '../models';
import { NodeFactory, NodeIds } from '../misc';
import { NodeBaseDto } from '../types';
import SessionStore from './SessionStore';
import SourceStore from './SourceStore';

export default class NodesStore extends Disposer {
    activeNodeId: string = '';

    nodes: NodeBaseModel[] = [];

    constructor(
        private readonly sessionStore: SessionStore,
        private readonly sourceStore: SourceStore
    ) {
        super();

        makeObservable(this, {
            activeNodeId: observable,
            nodes: observable,
            generalInformationNode: computed,
            setActiveNodeId: action.bound
        });

        this.reactions.push(
            reaction(
                () => this.sessionStore.response,
                () => {
                    if (this.sessionStore.inProgress) {
                        return;
                    }

                    runInAction(() => {
                        const data = this.sessionStore.response?.nodes ?? [];
                        this.nodes = this.createOrUpdateNodes(data, this.nodes);
                    });
                }
            )
        );

        this.reactions.push(
            reaction(
                () => this.nodes,
                () => {
                    if (this.activeNodeId && !this.nodes.some(t => t.id === this.activeNodeId)) {
                        this.setActiveNodeId('');
                    }

                    if (!this.activeNodeId && this.firstNode) {
                        this.setActiveNodeId(this.firstNode.id);
                    }
                }
            )
        );
    }

    get firstNode() {
        return first(this.nodes);
    }

    get generalInformationNode() {
        return this.nodes.find(node => node.id === NodeIds.GeneralInformation);
    }

    get limitsNode() {
        return this.nodes.find(node => node.id === NodeIds.Limits);
    }

    setActiveNodeId(activeNodeId: string) {
        this.activeNodeId = activeNodeId;
    }

    createOrUpdateNodes(data: NodeBaseDto[], existingNodes: NodeBaseModel[]): NodeBaseModel[] {
        return data.map(nodeDto => {
            const existingNode = existingNodes.find(t => t.id === nodeDto.id);

            if (existingNode) {
                existingNode.update(nodeDto, this.sourceStore.sources);

                existingNode.setChildNodes(
                    nodeDto.childNodes.length
                        ? [...this.createOrUpdateNodes(nodeDto.childNodes, existingNode.childNodes)]
                        : []
                );

                return existingNode;
            }

            const newNode = NodeFactory.createNode(nodeDto, this.sourceStore.sources);

            if (nodeDto.childNodes.length) {
                newNode.setChildNodes([...this.createOrUpdateNodes(nodeDto.childNodes, [])]);
            }

            return newNode;
        });
    }

    getNodeByInputGuid(inputGuid: string) {
        return this.nodes.find(node => node.inputs.some(input => input.guid === inputGuid));
    }

    dispose() {
        this.disposeReactions();
    }
}
