import { makeObservable, observable, action } from 'mobx';
import { LayerDto } from '../../types';
import { InputBaseModel, SourceModelBase, NodeBaseModel, LayerConfigurationModel } from '..';
import { InputFactory, UiUtils } from '../../misc';

export default class LayerModel {
    readonly id: string;

    readonly name: string;

    inputs: InputBaseModel[] = [];

    layerConfiguration: LayerConfigurationModel | null;

    expanded: boolean = false;

    constructor(
        private readonly node: NodeBaseModel,
        data: LayerDto,
        sources: SourceModelBase[]
    ) {
        this.id = data.id;
        this.name = data.name;
        this.inputs = InputFactory.createInputs(this.node, data.inputs, sources);
        this.layerConfiguration = data.layerConfiguration ? new LayerConfigurationModel(data.layerConfiguration) : null;

        makeObservable(this, {
            inputs: observable,
            expanded: observable,
            update: action,
            setExpanded: action.bound,
            toggleExpanded: action.bound
        });

        this.setExpanded(UiUtils.shouldExpandCollapse(this.inputs));
    }

    setExpanded(expanded: boolean) {
        this.expanded = expanded;
    }

    toggleExpanded() {
        this.setExpanded(!this.expanded);
    }

    getInputByGuid(guid: string) {
        return this.inputs.find(input => input.guid === guid);
    }

    update(data: LayerDto, sources: SourceModelBase[]) {
        this.inputs = InputFactory.createOrUpdateInputs(this.node, this.inputs, data.inputs, sources);
    }

    get dto(): LayerDto {
        return {
            id: this.id,
            name: this.name,
            inputs: this.inputs.map(input => input.dto),
            layerConfiguration: this.layerConfiguration ? this.layerConfiguration.dto : null
        };
    }
}
