import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse } from 'antd';
import { CollapseExpandIcon } from '../../../custom_shared/components';
import { InstanceUtils } from '../../misc';
import { NodeBaseModel } from '../../models';
import RenewalLayerRenderer from './RenewalLayerRenderer';
import './RenewalPanelRenderer.less';

interface Props {
    node: NodeBaseModel;
    panelName: string;
    panelKey: string;
    addRef: (panelKey: string, ref: React.RefObject<HTMLDivElement>) => void;
}

const RenewalPanelRenderer: React.FC<Props> = ({ node, panelName, panelKey, addRef }: Props) => {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        addRef(panelKey, ref);
    }, [addRef, panelKey]);

    if (!InstanceUtils.isLayersNode(node)) {
        console.error('RenewalPanelRenderer: node is not a LayersNode', node.type);
        return null;
    }

    return (
        <div ref={ref} className="renewal-panel-renderer-container">
            <Collapse bordered={false} expandIcon={CollapseExpandIcon} defaultActiveKey={['1']}>
                <Collapse.Panel
                    className="renewal-collapse-panel"
                    header={<span className="header-title">{panelName}</span>}
                    key="1"
                >
                    {node.layers.map(layer => (
                        <RenewalLayerRenderer key={layer.id} layer={layer} />
                    ))}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default observer(RenewalPanelRenderer);
