import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import { BasicNodeModel, LayersNodeModel, LayersWithSectionsNodeModel, SectionsNodeModel } from '../../models';
import { InstanceUtils } from '../../misc';
import NodeRenderer from './NodeRenderer';

const NodesRenderer: React.FC = () => {
    const { nodesStore, inputStore, documentVisualStore } = useAppStore();

    const handleBasicNodeExpand = React.useCallback(
        (node: BasicNodeModel, inputGuid: string) => {
            let addDelay = false;

            if (!node.expanded) {
                node.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    const handleLayersNodeExpand = React.useCallback(
        (node: LayersNodeModel, inputGuid: string) => {
            const layer = node.getLayerByInputGuid(inputGuid);

            if (!layer) {
                return;
            }

            let addDelay = false;

            if (!layer.expanded) {
                layer.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    const handleLayersWithSectionsNodeExpand = React.useCallback(
        (node: LayersWithSectionsNodeModel, inputGuid: string) => {
            const layer = node.getLayerByInputGuid(inputGuid);

            if (!layer) {
                return;
            }

            let addDelay = false;

            if (!layer.expanded) {
                layer.setExpanded(true);
                addDelay = true;
            }

            const section = layer.getSectionByInputGuid(inputGuid);

            if (!section) {
                return;
            }

            if (!section.expanded) {
                section.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    const handleSectionsNodeExpand = React.useCallback(
        (node: SectionsNodeModel, inputGuid: string) => {
            const section = node.getSectionByInputGuid(inputGuid);

            if (!section) {
                return;
            }

            let addDelay = false;

            if (!section.expanded) {
                section.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    React.useEffect(() => {
        const subject = documentVisualStore.highlightedInputIdSubject.subscribe(inputGuid => {
            if (!inputGuid) {
                inputStore.setHighlightedInputGuid(null);
                return;
            }

            const node = nodesStore.getNodeByInputGuid(inputGuid);

            if (!node) {
                return;
            }

            nodesStore.setActiveNodeId(node.id);

            if (InstanceUtils.isBasicNode(node)) {
                handleBasicNodeExpand(node, inputGuid);
            } else if (InstanceUtils.isLayersNode(node)) {
                handleLayersNodeExpand(node, inputGuid);
            } else if (InstanceUtils.isLayersWithSectionsNode(node)) {
                handleLayersWithSectionsNodeExpand(node, inputGuid);
            } else if (InstanceUtils.isSectionsNode(node)) {
                handleSectionsNodeExpand(node, inputGuid);
            }
        });

        return () => subject.unsubscribe();
    }, [
        nodesStore,
        inputStore,
        documentVisualStore.highlightedInputIdSubject,
        handleBasicNodeExpand,
        handleLayersNodeExpand,
        handleLayersWithSectionsNodeExpand,
        handleSectionsNodeExpand
    ]);

    return (
        <>
            {nodesStore.nodes.map(node => (
                <NodeRenderer key={node.id} node={node} />
            ))}
        </>
    );
};

export default observer(NodesRenderer);
