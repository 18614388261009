import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Select } from 'antd';
import { useAppStore } from '../../stores';
import { DataCrafterRuntimeInput } from '../../types';
import './DataCrafterDropdown.less';

interface Props {
    input: DataCrafterRuntimeInput;
    initialValue?: string;
    placeholder?: string;
}

const DataCrafterDropdown: React.FC<Props> = ({ input, placeholder = 'Select value' }) => {
    const { sessionStore } = useAppStore();

    // If no sourceId or source not found, render nothing
    if (!input.sourceId || !sessionStore.response?.sources.find(s => s.id === input.sourceId)) {
        return null;
    }

    const source = sessionStore.response.sources.find(s => s.id === input.sourceId)!;

    return (
        <Form.Item label={undefined} name={input.id}>
            <Select
                placeholder={placeholder}
                options={source.data.map(d => ({ label: d.text, value: d.value }))}
                allowClear
                showSearch
                filterOption={(searchValue, option) =>
                    (option?.label?.toString() || '').toLowerCase().includes(searchValue.toLowerCase())
                }
            />
        </Form.Item>
    );
};

export default observer(DataCrafterDropdown);
