import { LayoutSettingsBaseModel } from '..';
import { SectionsLayoutSettingsDto } from '../../types';

export default class SectionsLayoutSettingsModel extends LayoutSettingsBaseModel {
    readonly minNumberOfSections: number;
    readonly maxNumberOfSections: number;
    readonly numberOfSections: number;

    constructor(data: SectionsLayoutSettingsDto) {
        super(data);

        this.minNumberOfSections = data.minNumberOfSections;
        this.maxNumberOfSections = data.maxNumberOfSections;
        this.numberOfSections = data.numberOfSections;
    }

    get label() {
        return 'Sections Layout';
    }

    get dto(): SectionsLayoutSettingsDto {
        return {
            type: this.type,
            minNumberOfSections: this.minNumberOfSections,
            maxNumberOfSections: this.maxNumberOfSections,
            numberOfSections: this.numberOfSections
        };
    }
}
