import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from 'antd';
import { useAppStore } from '../../stores';
import NodeTitle from './NodeTitle';
import './NodesNavigation.less';

const NodesNavigation: React.FC = () => {
    const { nodesStore } = useAppStore();

    return (
        <div className="contract-ingestion-nodes-navigation-container">
            <Tabs
                className="contract-ingestion-nodes-navigation"
                activeKey={nodesStore.activeNodeId}
                onChange={nodesStore.setActiveNodeId}
            >
                {nodesStore.nodes.map(node => (
                    <Tabs.TabPane tab={<NodeTitle node={node} />} key={node.id} forceRender />
                ))}
            </Tabs>
        </div>
    );
};

export default observer(NodesNavigation);
