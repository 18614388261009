import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Utils } from '../../../common/misc/Utils';
import DocumentExcel from '../../../custom_shared/components/DocumentExcel/DocumentExcel';
import './DocumentPreview.less';

interface Props {
    packageId: string;
    packageName: string;
    packageUrl?: string;
    sheetsbar?: boolean;
}

const InvalidPreview: React.FC = () => {
    return <div className="data-crafter-document-preview">Invalid document format</div>;
};

const DocumentPreview: React.FC<Props> = ({ packageId, packageName, packageUrl, sheetsbar }) => {
    const isExcel = Utils.isExcelByExtension(packageName);

    if (!isExcel) {
        return <InvalidPreview />;
    }

    return (
        <div className="data-crafter-document-preview">
            <DocumentExcel
                packageId={packageId}
                packageName={packageName}
                packageUrl={packageUrl}
                sheetsbar={sheetsbar}
            />
        </div>
    );
};

export default observer(DocumentPreview);
