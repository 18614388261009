import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu } from 'antd';
import { FindDocumentIcon } from '../../../custom_shared/icons';
import { useAppStore } from '../../stores';

const key = 'find-matching-contracts';

const RenewalSuggestionMenuItem: React.FC = () => {
    const { settingsStore, renewalSuggestionsStore } = useAppStore();

    // Only basic layout is supported
    if (!settingsStore.isBasicLayoutSettings) {
        return null;
    }

    return (
        <Menu.Item
            key={key}
            eventKey={key}
            icon={<FindDocumentIcon />}
            onClick={() => renewalSuggestionsStore.retryRenewalSuggestionsSearch()}
        >
            Find Matching Contracts
        </Menu.Item>
    );
};

export default observer(RenewalSuggestionMenuItem);
