import { WidgetValuesProvider } from '../../../common/services/WidgetValuesProvider';
import { SourceModelBase } from '..';
import { CustomLinkedSource } from '../../../custom_shared/types';
import { Utils } from '../../misc';

export default class LinkedSourceModel extends SourceModelBase {
    readonly link: string;

    constructor(
        data: CustomLinkedSource,
        readonly widgetValuesProvider: WidgetValuesProvider
    ) {
        super(data);

        this.link = data.link;
    }

    async getSourceOptions() {
        const widgetData = await this.widgetValuesProvider.fetchAll(this.handleError);
        return this.mapData(widgetData.data);
    }

    async getSourceOptionsByParent(parentValues: unknown) {
        const widgetData = await this.widgetValuesProvider.fetchAllByParent(
            Utils.convertToStringArray(parentValues),
            true,
            this.handleError
        );

        return this.mapData(widgetData.data);
    }

    private handleError(error: string) {
        throw new Error(error);
    }
}
