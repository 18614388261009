import {
    GeneralInformationNodeRenderer,
    ClassificationsNodeRenderer,
    CountriesNodeRenderer,
    LimitsNodeRenderer,
    PremiumsNodeRenderer,
    DeductionsNodeRenderer,
    ShareNodeRenderer,
    ClaimsNodeRenderer,
    PortfolioConditionsNodeRenderer,
    AdminConditionsNodeRenderer,
    OtherConditionsNodeRenderer,
    InstalmentsNodeRenderer,
    AdjustmentsNodeRenderer,
    ReinstatementsNodeRenderer
} from '../../node_renderers';
import { NodeProps } from '../../componentTypes';
import { NodeIds } from '../../misc';

type Registry = Record<string, React.FC<NodeProps> | undefined>;

const NodeRendererRegistry: Registry = {
    [NodeIds.GeneralInformation]: GeneralInformationNodeRenderer,
    [NodeIds.Classifications]: ClassificationsNodeRenderer,
    [NodeIds.Countries]: CountriesNodeRenderer,
    [NodeIds.Limits]: LimitsNodeRenderer,
    [NodeIds.Premiums]: PremiumsNodeRenderer,
    [NodeIds.Deductions]: DeductionsNodeRenderer,
    [NodeIds.Share]: ShareNodeRenderer,
    [NodeIds.Claims]: ClaimsNodeRenderer,
    [NodeIds.PortfolioConditions]: PortfolioConditionsNodeRenderer,
    [NodeIds.AdminConditions]: AdminConditionsNodeRenderer,
    [NodeIds.OtherConditions]: OtherConditionsNodeRenderer,
    [NodeIds.Instalments]: InstalmentsNodeRenderer,
    [NodeIds.Adjustments]: AdjustmentsNodeRenderer,
    [NodeIds.Reinstatements]: ReinstatementsNodeRenderer
};

export default NodeRendererRegistry;
