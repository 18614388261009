import {
    LayoutSettingsBaseDto,
    BasicLayoutSettingsDto,
    LayersLayoutSettingsDto,
    LayersWithSectionsLayoutSettingsDto,
    LayerSettingsDto,
    SectionsLayoutSettingsDto,
    SettingsFormValues,
    LayerRange,
    SectionRange
} from '../types';
import {
    LayoutSettingsBaseModel,
    BasicLayoutSettingsModel,
    LayersLayoutSettingsModel,
    LayersWithSectionsLayoutSettingsModel,
    SectionsLayoutSettingsModel
} from '../models';
import { Utils, InstanceUtils } from '.';

const layerPrefix = 'Layer:';

export default class LayoutSettingsFactory {
    static createSettings(data: LayoutSettingsBaseDto): LayoutSettingsBaseModel {
        switch (data.type) {
            case 'Basic':
                return new BasicLayoutSettingsModel(data as BasicLayoutSettingsDto);
            case 'Layers':
                return new LayersLayoutSettingsModel(data as LayersLayoutSettingsDto);
            case 'LayersWithSections':
                return new LayersWithSectionsLayoutSettingsModel(data as LayersWithSectionsLayoutSettingsDto);
            case 'Sections':
                return new SectionsLayoutSettingsModel(data as SectionsLayoutSettingsDto);
            default:
                throw new Error('Unsupported settings type');
        }
    }

    static createSettingFromFormValues(
        formValues: SettingsFormValues,
        layoutSettingsOptions: LayoutSettingsBaseModel[]
    ): LayoutSettingsBaseModel | null {
        const dto: LayoutSettingsBaseDto = {
            type: formValues.type
        };

        if (dto.type === 'Basic') {
            return LayoutSettingsFactory.createSettings(dto);
        }

        const layoutSettings = layoutSettingsOptions.find(o => o.type === dto.type);
        const hasLayers = InstanceUtils.hasLayers(formValues);
        const hasSections = InstanceUtils.hasSections(formValues);
        const hasLayerRange = InstanceUtils.hasLayerRange(layoutSettings);
        const hasSectionRange = InstanceUtils.hasSectionRange(layoutSettings);

        if (dto.type === 'Layers' && hasLayers && hasLayerRange) {
            const data: LayersLayoutSettingsDto = {
                ...dto,
                ...LayoutSettingsFactory.extractMinMaxLayers(layoutSettings),
                numberOfLayers: formValues.numberOfLayers
            };

            return LayoutSettingsFactory.createSettings(data);
        }

        if (dto.type === 'LayersWithSections' && hasLayers && hasSections && hasLayerRange && hasSectionRange) {
            const layersSettings: LayerSettingsDto[] = Utils.createNumberArray(1, formValues.numberOfLayers).map(
                layerNumber => ({
                    layerNumber,
                    layerId: `${layerPrefix}${layerNumber}`,
                    numberOfSections: formValues.numberOfSections
                })
            );

            const data: LayersWithSectionsLayoutSettingsDto = {
                ...dto,
                ...LayoutSettingsFactory.extractMinMaxLayers(layoutSettings),
                ...LayoutSettingsFactory.extractMinMaxSections(layoutSettings),
                layersSettings
            };

            return LayoutSettingsFactory.createSettings(data);
        }

        if (dto.type === 'Sections' && hasSections && hasSectionRange) {
            const data: SectionsLayoutSettingsDto = {
                ...dto,
                ...LayoutSettingsFactory.extractMinMaxSections(layoutSettings),
                numberOfSections: formValues.numberOfSections
            };

            return LayoutSettingsFactory.createSettings(data);
        }

        return null;
    }

    private static extractMinMaxLayers(layoutSettings: LayoutSettingsBaseModel & LayerRange) {
        return {
            minNumberOfLayers: layoutSettings.minNumberOfLayers,
            maxNumberOfLayers: layoutSettings.maxNumberOfLayers
        };
    }

    private static extractMinMaxSections(layoutSettings: LayoutSettingsBaseModel & SectionRange) {
        return {
            minNumberOfSections: layoutSettings.minNumberOfSections,
            maxNumberOfSections: layoutSettings.maxNumberOfSections
        };
    }
}
