import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Form, Row, Col, Select, Radio, DatePicker, InputNumber, Button, Table, Tooltip, message } from 'antd';
import { Moment } from 'moment-timezone';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { ColumnProps } from 'antd/lib/table';
import { useAppStore } from '../../stores';
import { useTableScrollHeight } from '../../../custom_shared/hooks';
import { useMemoizedInput } from '../../hooks';
import { InputIds, Utils } from '../../misc';
import { BusinessDto, BusinessSearchCondition } from '../../types';
import { TypeUtils } from '../../../custom_shared/misc';
import './BusinessSearchModal.less';

const searchConditionOptions: Record<BusinessSearchCondition, { label: string; value: BusinessSearchCondition }> = {
    and: {
        label: 'And',
        value: 'and'
    },
    or: {
        label: 'Or',
        value: 'or'
    }
};

type FormValues = {
    businessPartners: string[];
    searchCondition: BusinessSearchCondition;
    insuredPeriodStart?: Moment;
    insuredPeriodEnd?: Moment;
    underwritingYear?: number;
};

const BusinessSearchModal: React.FC = () => {
    const appStore = useAppStore();

    const { tab, nodesStore, businessSearchStore } = appStore;

    const [form] = Form.useForm<FormValues>();

    const underwritingYearInput = useMemoizedInput(
        InputIds.GeneralInformation.UnderwritingYear,
        nodesStore.generalInformationNode?.inputs
    );

    const periodStartInput = useMemoizedInput(
        InputIds.GeneralInformation.PeriodStart,
        nodesStore.generalInformationNode?.inputs
    );

    const periodEndInput = useMemoizedInput(
        InputIds.GeneralInformation.PeriodEnd,
        nodesStore.generalInformationNode?.inputs
    );

    React.useEffect(() => {
        const underwritingYear = underwritingYearInput?.value;
        const periodStart = periodStartInput?.value;
        const periodEnd = periodEndInput?.value;

        form.setFieldsValue({
            underwritingYear: TypeUtils.isNumber(underwritingYear) ? underwritingYear : undefined,
            insuredPeriodStart: Utils.convertDateValueToMoment(periodStart) ?? undefined,
            insuredPeriodEnd: Utils.convertDateValueToMoment(periodEnd) ?? undefined
        });
    }, [form, underwritingYearInput?.value, periodStartInput?.value, periodEndInput?.value]);

    const { scrollHeight } = useTableScrollHeight(700);

    const onClose = () => {
        businessSearchStore.setModalVisible(false);
    };

    const onSubmit = () => {
        const insuredPeriodStart = form.getFieldValue('insuredPeriodStart');
        const insuredPeriodEnd = form.getFieldValue('insuredPeriodEnd');

        const filter = {
            ...form.getFieldsValue(),
            insuredPeriodStart: Utils.convertDateValueToString(insuredPeriodStart?.startOf('day')) ?? undefined,
            insuredPeriodEnd: Utils.convertDateValueToString(insuredPeriodEnd?.endOf('day')) ?? undefined
        };

        const hasAtLeastOneParameter = [
            filter.underwritingYear,
            filter.insuredPeriodStart,
            filter.insuredPeriodEnd,
            filter.businessPartners.length
        ].some(Boolean);

        if (!hasAtLeastOneParameter) {
            message.warning('You need to select at least one parameter to perform a search.');
            return;
        }

        businessSearchStore.updatePaging(0, businessSearchStore.pageSize);
        businessSearchStore.getBusinesses(filter);
    };

    const onBusinessClick = (business: BusinessDto) => {
        if (!businessSearchStore.inputsToUpdate) {
            return businessSearchStore.setModalVisible(false);
        }

        businessSearchStore.inputsToUpdate.businessIdInput.setValue(business.identifier);
        businessSearchStore.inputsToUpdate.businessIdInput.validate();
        businessSearchStore.inputsToUpdate.expiringPeriodStartInput.setValue(business.insuredPeriodStart);

        businessSearchStore.setModalVisible(false);
        businessSearchStore.setInputsToUpdate(null);
    };

    const onRow = (business: BusinessDto) => {
        return {
            onClick: () => onBusinessClick(business)
        };
    };

    const renderCell = (value: string | number) => <div className="table-cell-content">{value}</div>;

    const columns: ColumnProps<BusinessDto>[] = [
        {
            title: 'Business ID',
            dataIndex: 'identifier',
            key: 'identifier',
            render: (value: string) => renderCell(value),
            onCell: () => ({
                style: { minWidth: 80 }
            })
        },
        {
            title: 'Business Title',
            dataIndex: 'title',
            key: 'title',
            render: (value: string) => <Tooltip title={value}>{renderCell(value)}</Tooltip>,
            onCell: () => ({
                style: { maxWidth: 250 }
            })
        },
        {
            title: 'Underwriting Year',
            dataIndex: 'underwritingYear',
            key: 'underwritingYear',
            render: (value: number) => renderCell(value),
            onCell: () => ({
                style: { minWidth: 100 }
            })
        },
        {
            title: 'Cover',
            dataIndex: 'cover',
            key: 'cover',
            render: (value: number) => renderCell(Utils.formatAmount(value)),
            onCell: () => ({
                style: { minWidth: 80 }
            })
        },
        {
            title: 'Excess',
            dataIndex: 'excess',
            key: 'excess',
            render: (value: number) => renderCell(Utils.formatAmount(value)),
            onCell: () => ({
                style: { minWidth: 80 }
            })
        },
        {
            title: 'Lability',
            dataIndex: 'liability',
            key: 'liability',
            render: (value: number) => renderCell(Utils.formatAmount(value)),
            onCell: () => ({
                style: { minWidth: 80 }
            })
        }
    ];

    const pagination: TablePaginationConfig = {
        current: businessSearchStore.page + 1,
        pageSize: businessSearchStore.pageSize,
        hideOnSinglePage: true,
        pageSizeOptions: ['10', '25', '50'],
        onChange: (page, pageSize) => {
            businessSearchStore.updatePaging(page - 1, pageSize ?? 10);
        }
    };

    return (
        <Modal
            className="contract-ingestion-business-search-modal"
            title="Business Search"
            visible={businessSearchStore.modalVisible}
            maskClosable={false}
            width={1000}
            footer={null}
            destroyOnClose
            forceRender
            centered
            onCancel={onClose}
        >
            <Form id={`contract-ingestion-business-search-${tab.id}`} layout="vertical" form={form} preserve={false}>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item name="businessPartners" label="Business Partner" initialValue={[]}>
                            <Select
                                showSearch
                                allowClear
                                mode="multiple"
                                dropdownClassName="custom-select-dropdown"
                                options={businessSearchStore.businessPartnersOptions}
                                loading={businessSearchStore.businessPartnersLoading}
                                autoClearSearchValue={false}
                                filterOption={(value, option) =>
                                    option?.title && option.title.toLowerCase().includes(value.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="searchCondition"
                            label="Partner Filtering Operator"
                            initialValue={searchConditionOptions.and.value}
                        >
                            <Radio.Group
                                options={Object.values(searchConditionOptions).map(({ label, value }) => ({
                                    label,
                                    value
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item name="insuredPeriodStart" label="Insured Period Start Date">
                            <DatePicker placeholder="Select date" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="insuredPeriodEnd" label="Insured Period End Date">
                            <DatePicker placeholder="Select date" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item name="underwritingYear" label="Underwriting Year">
                            <InputNumber />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col className="find-now-column" span={24}>
                        <Button type="primary" onClick={onSubmit} disabled={businessSearchStore.businessesLoading}>
                            Find Now
                        </Button>
                    </Col>
                </Row>
            </Form>

            <Table
                className="business-search-table"
                dataSource={businessSearchStore.businesses}
                loading={businessSearchStore.businessesLoading}
                columns={columns}
                scroll={{ y: scrollHeight }}
                tableLayout="auto"
                rowKey={(business: BusinessDto) => businessSearchStore.businesses.indexOf(business).toString()}
                onRow={onRow}
                pagination={pagination}
            />
        </Modal>
    );
};

export default observer(BusinessSearchModal);
