import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useMemoizedInput } from '../../hooks';
import { useAppStore } from '../../stores';
import { ContractStatus, MoreActionsMenu, SendContractButton, RenewalSuggestionsControls, SettingsModal } from '..';
import { InputIds } from '../../misc';
import './AppHeader.less';

const AppHeader: React.FC = () => {
    const { appName, packageName, sessionStore, nodesStore, contractSubmissionStore } = useAppStore();

    const businessIdInput = useMemoizedInput(
        InputIds.GeneralInformation.BusinessId,
        nodesStore.generalInformationNode?.inputs
    );

    const expiringPeriodStartInput = useMemoizedInput(
        InputIds.GeneralInformation.ExpiringPeriodStart,
        nodesStore.generalInformationNode?.inputs
    );

    return (
        <div className="contract-ingestion-app-header">
            <div className="left-container">
                <span className="application-name">{appName}</span>
                <span className="package-name">{packageName}</span>
                {!sessionStore.isInitializing && businessIdInput && expiringPeriodStartInput && (
                    <ContractStatus
                        businessIdInput={businessIdInput}
                        expiringPeriodStartInput={expiringPeriodStartInput}
                    />
                )}
            </div>

            {!sessionStore.isInitializing && !contractSubmissionStore.contractSent && (
                <div className="right-container">
                    <SettingsModal />
                    <RenewalSuggestionsControls />
                    <MoreActionsMenu />
                    <SendContractButton />
                </div>
            )}
        </div>
    );
};

export default observer(AppHeader);
