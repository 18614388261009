import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse, Row, Col } from 'antd';
import { StyleUtils } from '../../misc';
import { GetComponentRowsFunction } from '../../componentTypes';
import { LayerModel, LayersNodeModel } from '../../models';
import { CollapseExpandIcon } from '../../../custom_shared/components';
import './LayerRenderer.less';

interface Props {
    node: LayersNodeModel;
    layer: LayerModel;
    getComponentRows: GetComponentRowsFunction;
    addLayerRef: (layerId: string, layerRef: React.RefObject<HTMLDivElement>) => void;
}

const LayerRenderer: React.FC<Props> = (props: Props) => {
    const { node, layer, getComponentRows, addLayerRef } = props;

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        addLayerRef(layer.id, ref);
    }, [layer.id, addLayerRef]);

    return (
        <div ref={ref} className="layer-renderer-container">
            <Collapse
                key={layer.id}
                activeKey={layer.expanded ? [layer.id] : []}
                onChange={layer.toggleExpanded}
                bordered={false}
                expandIcon={CollapseExpandIcon}
            >
                <Collapse.Panel
                    className="layer-collapse-panel"
                    header={<span className="header-title">{layer.name}</span>}
                    key={layer.id}
                >
                    {getComponentRows(layer.inputs, { NodeId: node.id, LayerId: layer.id }).map(
                        (componentRow, index) => (
                            <Row key={index} gutter={10}>
                                {componentRow.map(component => (
                                    <Col key={component.key} style={StyleUtils.getNodeColumnStyle(8)}>
                                        {component}
                                    </Col>
                                ))}
                            </Row>
                        )
                    )}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default observer(LayerRenderer);
