import { LayoutSettingsBaseModel, LayerSettingsModel } from '..';
import { LayersWithSectionsLayoutSettingsDto } from '../../types';
import { maxBy } from 'lodash';

export default class LayersWithSectionsLayoutSettingsModel extends LayoutSettingsBaseModel {
    readonly minNumberOfLayers: number;
    readonly minNumberOfSections: number;
    readonly maxNumberOfLayers: number;
    readonly maxNumberOfSections: number;
    readonly layersSettings: LayerSettingsModel[];

    constructor(data: LayersWithSectionsLayoutSettingsDto) {
        super(data);

        this.minNumberOfLayers = data.minNumberOfLayers;
        this.minNumberOfSections = data.minNumberOfSections;
        this.maxNumberOfLayers = data.maxNumberOfLayers;
        this.maxNumberOfSections = data.maxNumberOfSections;
        this.layersSettings = data.layersSettings.map(layerSettings => new LayerSettingsModel(layerSettings));
    }

    get label() {
        return 'Layers With Sections Layout';
    }

    get numberOfLayers() {
        return this.layersSettings.length;
    }

    get numberOfSections() {
        return maxBy(this.layersSettings, s => s.numberOfSections)?.numberOfSections ?? 0;
    }

    get dto(): LayersWithSectionsLayoutSettingsDto {
        return {
            type: this.type,
            minNumberOfLayers: this.minNumberOfLayers,
            minNumberOfSections: this.minNumberOfSections,
            maxNumberOfLayers: this.maxNumberOfLayers,
            maxNumberOfSections: this.maxNumberOfSections,
            layersSettings: this.layersSettings.map(s => s.dto)
        };
    }
}
