import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import { InputPackagesPreview, DataCrafterForm, OutputPreview } from '../../components';
import './DataCrafterAppScreen.less';
import '../../../custom_shared/styles/common.less';
import { Alert, Card, Spin } from 'antd';

const DataCrafterAppScreen: React.FC = () => {
    const appStore = useAppStore();

    const { sessionStore } = appStore;

    React.useEffect(() => {
        sessionStore.initialize();
    }, [sessionStore]);

    React.useEffect(() => {
        return () => appStore.dispose();
    }, [appStore]);

    return (
        <div className="data-crafter-app-screen">
            {sessionStore.errors.length > 0 && (
                <Alert
                    message="Error"
                    description={sessionStore.errors.map(error => error.message).join(', ')}
                    type="error"
                    className="data-crafter-error"
                />
            )}
            <Card className="data-crafter-card" title="Input Packages">
                <InputPackagesPreview />
            </Card>
            <Card className="data-crafter-card" title="Output Preview">
                {sessionStore.isLoading ? (
                    <div className="loading-container">
                        <Spin tip="Loading..." />
                    </div>
                ) : (
                    <>
                        <DataCrafterForm />
                        <OutputPreview />
                    </>
                )}
            </Card>
        </div>
    );
};

export default observer(DataCrafterAppScreen);
