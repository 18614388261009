import { TabModel } from '../../common/types/TabModel';
import { RootStore } from '../../common/stores/context';
import { Disposer } from '../../custom_shared/misc';
import SessionStore from './SessionStore';
import SourceStore, { DATA_CRAFTER_TEMPLATE_SOURCE } from './SourceStore';
import { DocumentVisualStore } from '../../../modules/documents/stores';
import ImageService from '../../../modules/common/services/ImageService';
import { DocumentService } from '../../../modules/common/services';
import { computed, makeObservable } from 'mobx';
import { StaticSourceModel } from '../models';
import { DataCrafterOutputColumn } from '../types';
import DataCrafterService from '../services/DataCrafterService';

const OUTPUT_COLUMNS_METADATA_KEY = 'outputColumns';

export default class AppStore extends Disposer {
    readonly sessionStore: SessionStore;
    readonly sourceStore: SourceStore;
    readonly documentVisualStore: DocumentVisualStore;

    constructor(
        readonly tab: TabModel,
        readonly rootStore: RootStore
    ) {
        super();

        const imageService = new ImageService();
        const documentService = new DocumentService();
        const dataCrafterService = new DataCrafterService();

        this.sessionStore = new SessionStore(this.tab, this.rootStore.iotaSessionStore, dataCrafterService);
        this.sourceStore = new SourceStore(this.sessionStore);
        this.documentVisualStore = new DocumentVisualStore(
            this.rootStore.applicationDefinitionsStore,
            imageService,
            this.rootStore.errorStore,
            documentService
        );

        makeObservable(this, {
            currentTemplateId: computed,
            currentTemplateColumns: computed
        });
    }

    get currentTemplateId() {
        return this.sessionStore.currentTemplateId;
    }

    get currentTemplateColumns() {
        if (!this.currentTemplateId) {
            return [];
        }

        const source = this.sourceStore.sources.find(s => s.id === DATA_CRAFTER_TEMPLATE_SOURCE);
        if (!source) {
            return [];
        }

        const staticSource = source as StaticSourceModel;
        const currentValue = staticSource.data.find(d => d.value === this.currentTemplateId);

        if (!currentValue) {
            return [];
        }

        return currentValue.metadata[OUTPUT_COLUMNS_METADATA_KEY] as DataCrafterOutputColumn[];
    }

    get appId() {
        return this.tab.metadata.applicationData.appId as string;
    }

    get appName() {
        return this.tab.metadata.applicationData.appName as string;
    }

    get sessionId() {
        return this.tab.metadata.sessionId as string;
    }

    get sessionExtension() {
        return this.tab.metadata.applicationData.appExtension as string;
    }

    get projectId() {
        return this.tab.metadata.projectId as string;
    }

    get packageId() {
        return this.tab.metadata.packageId as string;
    }

    get packageName() {
        return this.tab.metadata.packageName as string;
    }

    removeAppTab() {
        const tabIndex = this.rootStore.tabsStore.tabs.indexOf(this.tab);
        this.rootStore.tabsStore.removeTab(tabIndex);
    }

    dispose() {
        this.removeAppTab();
    }
}
