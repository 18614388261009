import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { TypeUtils } from '../../../custom_shared/misc';
import './RenewalSuggestionField.less';

const maxVisibleItems = 2;

interface Props {
    fieldLabel: string;
    fieldValue: unknown;
    fieldValuesDelimiter?: string;
    hideShowMoreOption?: boolean;
}

const RenewalSuggestionField: React.FC<Props> = ({
    fieldLabel,
    fieldValue,
    fieldValuesDelimiter = ',',
    hideShowMoreOption
}: Props) => {
    const [displayAllItems, setDisplayAllItems] = React.useState(false);
    const [hiddentItemsCount, setHiddenItemsCount] = React.useState(0);

    const renderShowMoreLabel = (values: string[]) => {
        const hiddenValues = values.slice(maxVisibleItems);
        return (
            <Tooltip key="show-more-tooltip" title={hiddenValues.join(', ')}>
                <a
                    className="show-more-label"
                    onClick={() => {
                        setDisplayAllItems(true);
                        setHiddenItemsCount(0);
                    }}
                >
                    and {hiddentItemsCount} more
                </a>
            </Tooltip>
        );
    };

    const getVisibleLabelsCount = (values: string[]) => {
        if (hideShowMoreOption) {
            return values.length;
        }

        return displayAllItems ? values.length : maxVisibleItems;
    };

    const renderMuplipleLabels = (values: string[]) => {
        const labels = values.slice(0, getVisibleLabelsCount(values)).map((value, index) => {
            const isLastItem = index === values.length - 1;
            const label = isLastItem ? value : `${value}${fieldValuesDelimiter}`;
            return (
                <span key={`${label}${index}`} className="field-value">
                    {label}
                </span>
            );
        });

        if (hiddentItemsCount > 0) {
            labels.push(renderShowMoreLabel(values));
        }

        if (!labels.length) {
            return null;
        }

        return <div className="field-values-container">{labels}</div>;
    };

    const renderSingleLabel = (value: number | string) => {
        return <span className="field-value">{value}</span>;
    };

    const renderFieldValue = () => {
        if (TypeUtils.isNumber(fieldValue) || TypeUtils.isString(fieldValue)) {
            return renderSingleLabel(fieldValue);
        }

        if (TypeUtils.isStringArray(fieldValue)) {
            return renderMuplipleLabels(fieldValue);
        }

        return null;
    };

    React.useEffect(() => {
        if (hideShowMoreOption) {
            return;
        }

        if (TypeUtils.isStringArray(fieldValue) && fieldValue.length > maxVisibleItems) {
            setHiddenItemsCount(fieldValue.length - maxVisibleItems);
        }
    }, [fieldValue, hideShowMoreOption]);

    return (
        <div className="renewal-suggestion-field-container">
            <span className="field-label">{fieldLabel}</span>
            {renderFieldValue()}
        </div>
    );
};

export default observer(RenewalSuggestionField);
