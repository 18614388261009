import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomSelectWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModelWithSource } from '../../models';
import { ValidationMessages } from '../../misc';
import { InputWithSource } from '../../componentTypes';
import { useAppStore } from '../../stores';

const config = {
    label: 'Broker'
};

const BrokerInput: InputWithSource = ({ input }) => {
    const { actionStore } = useAppStore();

    return (
        <CustomFormItem
            name={input.guid}
            required={input.required}
            message={ValidationMessages.requiredInput(config.label)}
        >
            <CustomSelectWrapper
                input={input}
                label={config.label}
                options={input.sourceProvider.options}
                topRightNode={<InputActions input={input} />}
                onChange={() => actionStore.rebuildNodes()}
            />
        </CustomFormItem>
    );
};

BrokerInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(BrokerInput);
