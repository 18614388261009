/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const CloudDownloadIcon: React.FC<IconProps> = ({ width = 24, height = 24, color = 'black', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M13.5547 15.8281V17.0859H17.2031C19.6016 17.0859 21.0391 15.6406 21.0391 13.75C21.0391 12.1719 20.0938 10.875 18.5703 10.2578C18.5859 6.77344 16.0781 4.25781 12.8828 4.25781C10.7656 4.25781 9.25781 5.39062 8.32031 6.75781C6.54688 6.28125 4.30469 7.65625 4.26562 9.85156C2.5 10.125 1.39844 11.5703 1.39844 13.4141C1.39844 15.4453 2.92188 17.0859 5.48438 17.0859H8.95312V15.8281H5.47656C3.625 15.8281 2.65625 14.7578 2.65625 13.4141C2.65625 11.9219 3.60156 10.8125 5.25781 10.8125C5.36719 10.8125 5.40625 10.75 5.40625 10.6484C5.40625 8.25 7.13281 7.58594 8.74219 8.05469C8.83594 8.08594 8.89062 8.07031 8.9375 7.98438C9.69531 6.63281 10.8594 5.51562 12.8828 5.51562C15.4453 5.51562 17.2188 7.54688 17.3516 9.875C17.375 10.2266 17.3516 10.6484 17.3203 11.0156C17.3125 11.1172 17.3516 11.1797 17.4453 11.1953C18.8672 11.5078 19.7812 12.3984 19.7812 13.75C19.7812 14.9531 18.9062 15.8281 17.1953 15.8281H13.5547ZM11.2578 21.7266C11.4141 21.7266 11.5391 21.6641 11.7031 21.5156L14.3438 19.0469C14.4688 18.9375 14.5312 18.8047 14.5312 18.6328C14.5312 18.3203 14.2812 18.0859 13.9609 18.0859C13.8125 18.0859 13.6562 18.1562 13.5391 18.2734L12.3516 19.4609L11.8281 20.0078L11.875 18.8438V12.1406C11.875 11.8125 11.5938 11.5312 11.2578 11.5312C10.9219 11.5312 10.6328 11.8125 10.6328 12.1406V18.8438L10.6797 20.0156L10.1562 19.4609L8.96875 18.2734C8.85156 18.1562 8.6875 18.0859 8.53906 18.0859C8.21094 18.0859 7.97656 18.3203 7.97656 18.6328C7.97656 18.8047 8.03906 18.9375 8.16406 19.0469L10.8047 21.5156C10.9688 21.6641 11.0938 21.7266 11.2578 21.7266Z"
            fill={color}
        />
        <path
            d="M13.5547 15.8281V17.0859H17.2031C19.6016 17.0859 21.0391 15.6406 21.0391 13.75C21.0391 12.1719 20.0938 10.875 18.5703 10.2578C18.5859 6.77344 16.0781 4.25781 12.8828 4.25781C10.7656 4.25781 9.25781 5.39062 8.32031 6.75781C6.54688 6.28125 4.30469 7.65625 4.26562 9.85156C2.5 10.125 1.39844 11.5703 1.39844 13.4141C1.39844 15.4453 2.92188 17.0859 5.48438 17.0859H8.95312V15.8281H5.47656C3.625 15.8281 2.65625 14.7578 2.65625 13.4141C2.65625 11.9219 3.60156 10.8125 5.25781 10.8125C5.36719 10.8125 5.40625 10.75 5.40625 10.6484C5.40625 8.25 7.13281 7.58594 8.74219 8.05469C8.83594 8.08594 8.89062 8.07031 8.9375 7.98438C9.69531 6.63281 10.8594 5.51562 12.8828 5.51562C15.4453 5.51562 17.2188 7.54688 17.3516 9.875C17.375 10.2266 17.3516 10.6484 17.3203 11.0156C17.3125 11.1172 17.3516 11.1797 17.4453 11.1953C18.8672 11.5078 19.7812 12.3984 19.7812 13.75C19.7812 14.9531 18.9062 15.8281 17.1953 15.8281H13.5547ZM11.2578 21.7266C11.4141 21.7266 11.5391 21.6641 11.7031 21.5156L14.3438 19.0469C14.4688 18.9375 14.5312 18.8047 14.5312 18.6328C14.5312 18.3203 14.2812 18.0859 13.9609 18.0859C13.8125 18.0859 13.6562 18.1562 13.5391 18.2734L12.3516 19.4609L11.8281 20.0078L11.875 18.8438V12.1406C11.875 11.8125 11.5938 11.5312 11.2578 11.5312C10.9219 11.5312 10.6328 11.8125 10.6328 12.1406V18.8438L10.6797 20.0156L10.1562 19.4609L8.96875 18.2734C8.85156 18.1562 8.6875 18.0859 8.53906 18.0859C8.21094 18.0859 7.97656 18.3203 7.97656 18.6328C7.97656 18.8047 8.03906 18.9375 8.16406 19.0469L10.8047 21.5156C10.9688 21.6641 11.0938 21.7266 11.2578 21.7266Z"
            fill={color}
            fillOpacity="0.2"
        />
    </svg>
);

export default CloudDownloadIcon;
