import { TabModel } from '../../common/types/TabModel';
import { IotaSessionsStore } from '../../common/stores';
import { SessionStoreBase } from '../../custom_shared/stores';
import { DataCrafterRuntimeAppDto } from '../types';
import { observable, action, makeObservable, computed } from 'mobx';
import DataCrafterService from '../services/DataCrafterService';
import { Utils } from '../../common/misc/Utils';
enum Actions {
    PrepareOutput = 'PrepareOutput'
}

export default class SessionStore extends SessionStoreBase<DataCrafterRuntimeAppDto> {
    currentTemplateId: string | undefined = undefined;

    constructor(
        tab: TabModel,
        iotaSessionsStore: IotaSessionsStore,
        private dataCrafterService: DataCrafterService
    ) {
        super(tab, iotaSessionsStore);

        makeObservable(this, {
            currentTemplateId: observable,
            setCurrentTemplateId: action.bound,
            processedPackageId: computed,
            fetchOutputDocument: action.bound
        });
    }

    get processedPackageId() {
        return this.response?.processedPackageId;
    }

    setCurrentTemplateId(templateId: string | undefined, processTemplate: boolean = true) {
        this.currentTemplateId = templateId;

        if (templateId && processTemplate) {
            this.continueSession(Actions.PrepareOutput, {
                DataCrafterTemplate: { value: templateId }
            });
        }
    }

    async fetchOutputDocument() {
        if (!this.processedPackageId) {
            return;
        }

        try {
            const blob = await this.dataCrafterService.fetchOutputDocument(this.sessionId, this.processedPackageId);

            // Create a download link and trigger the download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;

            const date = new Date();
            const formattedDate = Utils.formatDateShort(date).replace(',', '_').replace(/\s+/g, '');
            a.download = `${formattedDate}_DataCrafterOutput_${this.processedPackageId}.xlsx`;

            document.body.appendChild(a);
            a.click();

            // Cleanup
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Failed to fetch output document:', error);
            throw error;
        }
    }
}
