import { makeObservable, observable, action } from 'mobx';
import { LayerConfigurationDto } from '../../types';

export default class LayerConfigurationModel {
    readonly showSubmissionToggle: boolean;
    skipSubmission: boolean;

    constructor(data: LayerConfigurationDto) {
        this.showSubmissionToggle = data.showSubmissionToggle;
        this.skipSubmission = data.skipSubmission;

        makeObservable(this, {
            skipSubmission: observable,
            toggleSkipSubmission: action.bound
        });
    }

    toggleSkipSubmission() {
        this.skipSubmission = !this.skipSubmission;
    }

    get dto(): LayerConfigurationDto {
        return {
            showSubmissionToggle: this.showSubmissionToggle,
            skipSubmission: this.skipSubmission
        };
    }
}
