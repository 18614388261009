import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { useAppStore } from '../../stores';
import DocumentPreview from '../DocumentPreview';
import './InputPackagesPreview.less';

const InputPackagesPreview: React.FC = () => {
    const { sessionStore } = useAppStore();

    // Create tab panes for each input package
    const tabPanes = React.useMemo(() => {
        if (!sessionStore.response?.inputPackages) {
            return [];
        }

        return sessionStore.response.inputPackages.map(pkg => (
            <Tabs.TabPane
                key={pkg.id}
                tab={
                    <>
                        <FileOutlined />
                        {pkg.name}
                    </>
                }
                className="input-package-tab-pane"
            >
                <DocumentPreview packageId={pkg.id} packageName={pkg.name} />
            </Tabs.TabPane>
        ));
    }, [sessionStore.response]);

    if (!tabPanes.length) {
        return null;
    }

    return (
        <div className="input-packages-preview">
            <Tabs>{tabPanes}</Tabs>
        </div>
    );
};

export default observer(InputPackagesPreview);
