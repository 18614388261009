import { makeObservable, observable, action, reaction } from 'mobx';
import { Disposer } from '../../custom_shared/misc';
import { TypeUtils } from '../../custom_shared/misc';
import { ActionParams } from '../types';
import AppStore from './AppStore';
import SessionStore from './SessionStore';
import RuntimeDataStore from './RuntimeDataStore';

const Actions = {
    ChangeBusinessType: 'ChangeBusinessType',
    AddLimit: 'AddLimit',
    AddDeduction: 'AddDeduction',
    RebuildNodes: 'RebuildNodes',
    SendContract: 'SendContract',
    ExportToJson: 'ExportToJson',
    ClearPostAction: 'ClearPostAction'
} as const;

export const ActionParamKeys = {
    NodeId: 'NodeId',
    LayerId: 'LayerId',
    SectionId: 'SectionId'
} as const;

export default class ActionStore extends Disposer {
    downloadUrl: string | null = null;

    constructor(
        private readonly appStore: AppStore,
        private readonly sessionStore: SessionStore,
        private readonly runtimeDataStore: RuntimeDataStore
    ) {
        super();

        makeObservable(this, {
            downloadUrl: observable,
            setDownloadUrl: action
        });

        this.reactions.push(
            reaction(
                () => this.sessionStore.response,
                () => {
                    if (!this.sessionStore.response || this.sessionStore.inProgress) {
                        return;
                    }

                    const postAction = this.sessionStore.response.postAction;
                    const downloadAction = postAction && postAction.type === 'Download' ? postAction : null;

                    if (!downloadAction) {
                        return this.setDownloadUrl(null);
                    }

                    const downloadUrl = TypeUtils.isString(downloadAction.metadata.url)
                        ? downloadAction.metadata.url
                        : null;

                    this.setDownloadUrl(downloadUrl);
                }
            )
        );

        this.reactions.push(
            reaction(
                () => this.downloadUrl,
                () => {
                    if (!this.downloadUrl) {
                        return;
                    }

                    const downloadMessage = `Do you want to download file produced after executing '${this.appStore.applicationData.appName}' on '${this.appStore.packageName}'?`;

                    if (confirm(downloadMessage)) {
                        this.sessionStore.getFileFromIotaTempStorage(this.downloadUrl);
                        this.clearPostAction();
                    } else {
                        this.clearPostAction();
                    }
                }
            )
        );
    }

    setDownloadUrl(downloadUrl: string | null) {
        this.downloadUrl = downloadUrl;
    }

    async changeBusinessType() {
        await this.sessionStore.continueSession(Actions.ChangeBusinessType, this.runtimeDataStore.getRuntimeData());
    }

    async addLimit(actionParams: ActionParams) {
        await this.sessionStore.continueSession(
            Actions.AddLimit,
            this.runtimeDataStore.getRuntimeData({ [RuntimeDataStore.RuntimeDataKeys.ActionParams]: actionParams })
        );
    }

    async addDeduction(actionParams: ActionParams) {
        await this.sessionStore.continueSession(
            Actions.AddDeduction,
            this.runtimeDataStore.getRuntimeData({ [RuntimeDataStore.RuntimeDataKeys.ActionParams]: actionParams })
        );
    }

    async rebuildNodes() {
        await this.sessionStore.continueSession(Actions.RebuildNodes, this.runtimeDataStore.getRuntimeData());
    }

    async sendContract() {
        await this.sessionStore.continueSession(Actions.SendContract, this.runtimeDataStore.getRuntimeData());
    }

    async exportToJson() {
        await this.sessionStore.continueSession(Actions.ExportToJson, this.runtimeDataStore.getRuntimeData());
    }

    async clearPostAction() {
        await this.sessionStore.continueSession(Actions.ClearPostAction, this.runtimeDataStore.getRuntimeData());
    }

    dispose() {
        this.disposeReactions();
    }
}
