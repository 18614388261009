import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu } from 'antd';
import { CloudDownloadIcon } from '../../../custom_shared/icons';
import { useAppStore } from '../../stores';

const key = 'export-to-json';

const ExportToJsonMenuItem: React.FC = () => {
    const { actionStore } = useAppStore();

    return (
        <Menu.Item key={key} eventKey={key} icon={<CloudDownloadIcon />} onClick={() => actionStore.exportToJson()}>
            Export To JSON
        </Menu.Item>
    );
};

export default observer(ExportToJsonMenuItem);
