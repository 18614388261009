import { NodeBaseDto, BasicNodeDto, LayersNodeDto, LayersWithSectionsNodeDto, SectionsNodeDto } from '../types';
import {
    SourceModelBase,
    BasicNodeModel,
    LayersNodeModel,
    LayersWithSectionsNodeModel,
    SectionsNodeModel
} from '../models';

export default class NodeFactory {
    static createNode(data: NodeBaseDto, sources: SourceModelBase[]) {
        switch (data.type) {
            case 'Basic':
                return new BasicNodeModel(data as BasicNodeDto, sources);
            case 'Layers':
                return new LayersNodeModel(data as LayersNodeDto, sources);
            case 'LayersWithSections':
                return new LayersWithSectionsNodeModel(data as LayersWithSectionsNodeDto, sources);
            case 'Sections':
                return new SectionsNodeModel(data as SectionsNodeDto, sources);
            default:
                throw new Error('Unsupported node type');
        }
    }
}
